import React, { useCallback } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { request, useAuth } from 'auth';
import { Routes } from 'routes';
import Layout, { Content } from 'antd/es/layout/layout';
import FormWrapper from 'components/FormWrapper';
import styles from 'pages/ForgotPassword/styles.module.scss';
import { Button, Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { showSuccessToast } from 'utils/makeToast';

const ForgotPasswordConfirm = () => {
  const [isUserLogged] = useAuth();
  const history = useHistory();
  const { token } = useParams();

  const onFinish = useCallback((form) => {
    request(`/api/update_password/${token}`, {
      method: 'post',
      body: JSON.stringify(form),
    })
      .then(({ data }) => {
        showSuccessToast('Обновление пароля прошло успешно');
        history.push(Routes.root);
      });
  }, []);

  if (isUserLogged) {
    return <Redirect to={Routes.root} />;
  }

  return (
    <Layout>
      <Content
        className="site-layout-background"
      >
        <FormWrapper
          title="Задайте новый пароль"
          customClassName={styles.wrapper}
        >
          <Form
            name="normal_login"
            layout="vertical"
            size="large"
            className="login-form"
            onFinish={onFinish}
          >
            <Form.Item
              name="password"
              rules={[{
                required: true,
                message: 'Пожалуйста, создайте пароль!',
              }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Пароль"
              />
            </Form.Item>
            <Form.Item
              name="repeatPassword"
              dependencies={['password']}
              rules={[{
                required: true,
                message: 'Пароли должны совпадать',
              }, ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Пароли должны совпадать!'));
                },
              })]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Подтвердите пароль"
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.loginButton}
            >
              Обновить
            </Button>
          </Form>
        </FormWrapper>
      </Content>
    </Layout>
  );
};

export default ForgotPasswordConfirm;
