import React from 'react';

import { Button, Space } from 'antd';
import {
  DollarCircleOutlined, UserSwitchOutlined, CodeOutlined,
} from '@ant-design/icons';

import { bool, func } from 'prop-types';
import { noop } from 'utils';

const EditCampaignButtons = ({
  isMainAccount,
  showAddBalanceDrawer,
  showAssignmentDrawer,
  showRenameDrawer,
}) => {
  if (!isMainAccount) {
    return null;
  }

  return (
    <Space>
      <Button
        icon={<DollarCircleOutlined />}
        onClick={showAddBalanceDrawer}
      >
        Пополнить баланс
      </Button>
      <Button
        icon={<UserSwitchOutlined />}
        onClick={showAssignmentDrawer}
      >
        Доступы
      </Button>
      <Button
        icon={<CodeOutlined />}
        onClick={showRenameDrawer}
      >
        Переименовать
      </Button>
    </Space>
  );
};

EditCampaignButtons.propTypes = {
  isMainAccount: bool,
  showAddBalanceDrawer: func,
  showAssignmentDrawer: func,
  showRenameDrawer: func,
};

EditCampaignButtons.defaultProps = {
  isMainAccount: false,
  showAddBalanceDrawer: noop,
  showAssignmentDrawer: noop,
  showRenameDrawer: noop,
};

export default EditCampaignButtons;
