export const Routes = {
  root: '/',
  endUsers: '/end_users',
  clientsByAccount: '/accounts/:account_id/clients',
  campaignsByClient: '/clients/:client_id/campaigns',
  adsByCampaign: '/campaigns/:campaign_id/ads',
  ad: '/ads/:ad_id',
  accounts: '/accounts',
  loginCallback: '/login_callback',
  confirmUser: '/confirm/:token',
  forgotPasswordConfirm: '/forgot/:token',
  forgotPassword: '/forgot',
  logout: '/logout',
  notFound: '/404-not-found',
  login: '/login',
  balance: '/balance',
  referrals: '/referrals',
  successPaymentCallback: '/payment/success',
  failPaymentCallback: '/payment/fail',
  massEdit: '/mass_edit',
  registration: '/registration',
  products: '/products',
  landingOld: '/landing_old',
  landing: '/landing',
  contacts: '/contacts',
  admin: '/admin',
};
