import React, { useCallback } from 'react';
import {
  Link, Redirect, useHistory, useLocation,
} from 'react-router-dom';

import {
  Button, Checkbox, Form, Input,
} from 'antd';
import Layout, { Content } from 'antd/es/layout/layout';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import consentFile from 'assets/files/consent_signup.pdf';

import { request, useAuth } from 'auth';
import { showErrorToast, showSuccessToast } from 'utils/makeToast';
import { Routes } from 'routes';
import FormWrapper from 'components/FormWrapper';

import styles from './styles.module.scss';

const Registration = () => {
  const { search } = useLocation();
  const [isUserLogged] = useAuth();
  const history = useHistory();

  const onSubmitForm = useCallback((form) => {
    const refererUserId = new URLSearchParams(search).get('refererUserId');
    request('/api/register?', {
      method: 'post',
      body: JSON.stringify({ ...form, ...{ refererUserId } }),
    })
      .then(({ data }) => {
        console.log(data);
        showSuccessToast('Регистрация прошла успешно! Для продолжения работы мы отправили вам письмо на указанную почту');
        history.push(Routes.root);
      })
      .catch((error) => {
        showErrorToast(`${error.error.status} ${error.error.message}` || 'При регистрации возникла ошибка');
        console.error(error);
      });
  }, [history]);

  if (isUserLogged) {
    return <Redirect to={Routes.root} />;
  }

  return (
    <Layout>
      <Content
        className="site-layout-background"
      >
        <FormWrapper
          title="Регистрация"
          customClassName={styles.wrapper}
        >
          <Form
            name="in-app-registration"
            layout="vertical"
            size="large"
            onFinish={onSubmitForm}
          >
            <Form.Item
              name="firstName"
              rules={[{
                required: true,
                message: 'Пожалуйста, ведите Ваше имя. От 2 до 64 символов.',
                max: 64,
                min: 2,
              }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Имя"
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              rules={[{
                required: true,
                message: 'Пожалуйста, ведите Вашу Фамилию. От 2 до 64 символов.',
                max: 64,
                min: 2,
              }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Фамилия"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Такого email не существует или он не подходит под правила нашего сервиса. Пожалуйста, введите другой email',
                },
                {
                  required: true,
                  message: 'Пожалуйста, введите email. Он поднадобится для подтверждения создаваемого аккаунта. От 4 до 64 символов.',
                  max: 64,
                  min: 4,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{
                required: true,
                message: 'Пожалуйста, создайте пароль! От 4 до 64 символов.',
                max: 64,
                min: 4,
              }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Пароль"
              />
            </Form.Item>
            <Form.Item
              name="repeatPassword"
              dependencies={['password']}
              rules={[{
                required: true,
                message: 'Пароли должны совпадать',
              }, ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Пароли должны совпадать!'));
                },
              })]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Подтвердите пароль"
              />
            </Form.Item>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Дайте согласие на обработку данных'))),
                },
              ]}
            >
              <Checkbox>
                Я даю свое
                {' '}
                <a
                  href={consentFile}
                  target="_blank"
                  rel="noreferrer"
                >
                  согласие
                </a>
                {' '}
                на обработку своих персональных данных
              </Checkbox>
            </Form.Item>

            <div className={styles.additionalNotes}>
              Уже есть аккаунт?
              <Link to={Routes.login}>
                Войти
              </Link>
            </div>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.registerButton}
              >
                Зарегистрироваться
              </Button>
            </Form.Item>
          </Form>
        </FormWrapper>
      </Content>
    </Layout>
  );
};

export default Registration;
