import React, { useCallback, useState } from 'react';

import {
  Button,
  Drawer, Form, Input, List,
} from 'antd';

import { bool, func } from 'prop-types';
import { authRequest } from 'auth';
import { useCurrentEndUser } from 'containers/CurrentUserContextProvider/endUserContextProvider';
import * as propTypes from 'prop-types';
import { showErrorToast, showInfoToast } from 'utils/makeToast';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import Avatar from 'antd/es/avatar/avatar';

const AssignClientDrawer = ({
  clientId,
  assignDrawerVisible,
  onCloseAssignDrawer,
}) => {
  const currentEndUser = useCurrentEndUser();
  const [assignUsers, setAssignUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const onClickUpdateUsersBtn = useCallback((event) => {
    event.preventDefault();
    const userIds = assignUsers.map((user) => user.id);
    authRequest(
      `/api/vk/clients/${clientId}?vk_user_id=${currentEndUser.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          user_ids: userIds,
        }),
      },
      false,
      null,
    )
      .then(({ data: response = {} }) => {
        if (response) {
          console.log(response);
        }
      });
    onCloseAssignDrawer();
  }, [assignUsers]);

  const onClickClearButton = useCallback((event) => {
    event.preventDefault();
    setAssignUsers([]);
  }, []);

  const onSubmitAddUserForm = useCallback((form) => {
    setLoading(true);
    authRequest(
      `/api/vk/users/search?vk_user_id=${currentEndUser.id}`,
      {
        method: 'post',
        body: JSON.stringify(form),
      },
      false,
      null,
    )
      .then(({ data: response = {} }) => {
        let exist = false;
        if (response) {
          assignUsers.forEach((user) => {
            if (user.id === response.id) {
              exist = true;
            }
          });
          if (exist) {
            showInfoToast('Пользователь уже в списке');
          } else {
          // eslint-disable-next-line no-shadow
            setAssignUsers((assignUsers) => [response, ...assignUsers]);
          }
        } else {
          showErrorToast('Пользователь не найден');
        }
        setLoading(false);
      })
      .catch((error) => {
        showErrorToast('Идёт скачивание данных с VK. Достигнут лимит. Попробуйте позже.');
        setLoading(false);
        console.error(error);
      });
  }, [assignUsers]);

  return (
    <Drawer
      title="Доступы. Добавление пользователей."
      placement="right"
      width={500}
      visible={assignDrawerVisible}
      onClose={onCloseAssignDrawer}
    >
      <Form
        layout="inline"
        onFinish={onSubmitAddUserForm}
        hideRequiredMark
      >
        <Form.Item
          style={{ width: '75%' }}
          name="user_search_query"
          rules={[{ required: true, message: 'Введите ссылку на пользователя vk' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            icon={<PlusOutlined />}
          />
        </Form.Item>
        <Form.Item>
          <Button
            onClick={onClickClearButton}
            disabled={loading}
            icon={<DeleteOutlined />}
          />
        </Form.Item>
      </Form>

      <List
        itemLayout="horizontal"
        dataSource={assignUsers}
        locale={{ emptyText: '...' }}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={item.photo_50} />}
              title={`${item.first_name} ${item.last_name}`}
            />
          </List.Item>
        )}
      />
      <Button
        type="primary"
        onClick={onClickUpdateUsersBtn}
        disabled={assignUsers.length ? loading : true}
      >
        Применить
      </Button>

    </Drawer>
  );
};

AssignClientDrawer.propTypes = {
  clientId: propTypes.string.isRequired,
  assignDrawerVisible: bool.isRequired,
  onCloseAssignDrawer: func.isRequired,
};

export default AssignClientDrawer;
