import React from 'react';
import { Layout } from 'antd';

import RouterContainer from 'containers/Router';
import SideMenu from 'containers/SideMenu';
import Footer from 'components/Footer';

import styles from './styles.module.scss';

const staticStyle = { minHeight: '100vh' };

const CommonLayout = () => (
  <Layout
    className={styles.wrapper}
    style={staticStyle}
  >
    <SideMenu />
    <Layout className="site-layout">
      <RouterContainer />
      <Footer />
    </Layout>
  </Layout>
);

export default CommonLayout;
