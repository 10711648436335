import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import CurrentUserContextProvider from 'containers/CurrentUserContextProvider';
import CommonLayout from 'containers/CommonLayout';

import './App.css';

const App = () => (
  <Router>
    <CurrentUserContextProvider>
      <CommonLayout />
    </CurrentUserContextProvider>
  </Router>
);

export default App;
