import { useCallback } from 'react';

export const useHandleTableChange = (params, fetchFunc) => {
  // eslint-disable-next-line no-unused-vars
  const handleTableChange = useCallback((pagination, filters, sorter) => {
    const { pageSize, current } = pagination;

    fetchFunc(pageSize, current, params?.currentEndUser?.id);
  }, [fetchFunc, params]);

  return handleTableChange;
};
