import React, { useCallback, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { Breadcrumb } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';

import { authRequest } from 'auth';
import { useFetchTableData } from 'hooks/useFetchTableData';
import { useCurrentEndUser } from 'containers/CurrentUserContextProvider/endUserContextProvider';

const Ad = () => {
  /* eslint-disable no-unused-vars */
  const [lastSuccessDateTime, setLastSuccessDateTime] = useState('');
  const [account, setAccount] = useState();
  const [client, setClient] = useState();
  const [campaign, setCampaign] = useState();
  const [ad, setAd] = useState([]);
  const [isAsyncUpdating, setIsAsyncUpdating] = useState();
  const [loading, setLoading] = useState(false);
  const [actualExtra, setActualExtra] = useState({});
  const [prevAdId, setPrevAdId] = useState();
  const [nextAdId, setNextAdId] = useState();
  /* eslint-enable no-unused-vars */
  const currentEndUser = useCurrentEndUser();

  const { ad_id: adId } = useParams();

  const fetchData = useCallback((pageSize, currentPage, userId) => {
    authRequest(`/api/vk/ads/${adId}?vk_user_id=${userId}`)
      .then(({ data: response = {} }) => {
        setLoading(false);
        setLastSuccessDateTime(response.last_success_dt);
        setCampaign(response.campaign);
        setAccount(response.account);
        setClient(response.client);
        setAd(response.ad);
        setIsAsyncUpdating(response.is_async_updating_works);
        setActualExtra(response.actual_extra);
        setPrevAdId(response.prev_ad_id);
        setNextAdId(response.next_ad_id);
      });
  }, [adId]);

  useFetchTableData(null, currentEndUser, fetchData, setLoading);

  if (!currentEndUser) {
    return <Redirect to="" />;
  }

  return (
    <>
      <Header className="site-layout-sub-header-background">
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>...</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Content style={{ margin: '0 16px' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <h3>{ad ? ad.name : ''}</h3>
        </div>
      </Content>
    </>
  );
};

export default Ad;
