import React, { useCallback } from 'react';
import {
  Redirect,
} from 'react-router-dom';

import { Button, Form, Input } from 'antd';
import Layout, { Content } from 'antd/es/layout/layout';
import { UserOutlined } from '@ant-design/icons';

import { request, useAuth } from 'auth';
import { Routes } from 'routes';
import FormWrapper from 'components/FormWrapper';

import { showSuccessToast } from 'utils/makeToast';
import styles from './styles.module.scss';

const ForgotPassword = () => {
  const [isUserLogged] = useAuth();
  const onFinish = useCallback((form) => {
    request('/api/forgot', {
      method: 'post',
      body: JSON.stringify(form),
    })
      .then(({ data }) => {
        showSuccessToast('На указанную почту отправлено письмо со ссылкой для восстановления пароля');
      });
  }, []);

  if (isUserLogged) {
    return <Redirect to={Routes.root} />;
  }

  return (
    <Layout>
      <Content
        className="site-layout-background"
      >
        <FormWrapper
          title="Восстановление пароля"
          customClassName={styles.wrapper}
        >
          <Form
            name="normal_login"
            layout="vertical"
            size="large"
            className="login-form"
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[{
                required: true,
                message: 'Пожалуйста, ведите Ваш email адрес!',
              }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.loginButton}
            >
              Восстановить
            </Button>
          </Form>
        </FormWrapper>
      </Content>
    </Layout>
  );
};

export default ForgotPassword;
