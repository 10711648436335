import { notification } from 'antd';

// available types from antd docs
const TOAST_TYPES = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

export const showToast = (type, title, description) => {
  if (TOAST_TYPES[type]) {
    notification[type]({
      message: title,
      description,
    });
  } else {
    console.error('No such type of toast');
  }
};

export const showErrorToast = (title, description) => {
  showToast(TOAST_TYPES.error, title, description);
};

export const showWarningToast = (title, description) => {
  showToast(TOAST_TYPES.warning, title, description);
};

export const showInfoToast = (title, description) => {
  showToast(TOAST_TYPES.info, title, description);
};

export const showSuccessToast = (title, description) => {
  showToast(TOAST_TYPES.success, title, description);
};
