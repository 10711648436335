import React from 'react';
import { Content } from 'antd/es/layout/layout';
import {
  Col,
  List,
  Row,
} from 'antd';
import offerAgreement from 'assets/files/offer_agreement_draft.pdf';
import opisProc from 'assets/files/opisane_proc.pdf';

const data = [
  {
    text: 'Документация программного обеспечения «Сервис автоматизированного управления трафиком»',
    link: offerAgreement,
  },
  {
    text: 'Описание процессов, обеспечивающих поддержание жизненного цикла программного обеспечения',
    link: opisProc,
  },
];
const renderItem = (item) => (
  <List.Item>
    <a
      href={item.link}
      target="_blank"
      rel="noreferrer"
    >
      {item.text}
    </a>
  </List.Item>
);

const ProductsPage = () => (
  <Row>
    <Col lg={{ span: 16, offset: 4 }}>
      <Content style={{ padding: '0 50px' }}>
        <h1>Сервис автоматизированного управления трафиком</h1>
        <div>
          <p>
            ПО предназначено для оптимизации бюджетов по целевой аудитории
          </p>
          <p>
            Работает с рекламными кампаниями ВК, MyTarget, tiktok, Яндекс
          </p>
          <p>
            С помощью сервиса вы можете:
            <ul>
              <li>Оптимизировать рекламу с выгодой до 25% от суммы расходов</li>
              <li>Выводить кэшбэк от рекламы любым удобным способом без комиссии</li>
              <li>Получать развернутую аналитику в 1 месте</li>
              <li>Быстрее проходить модерацию объявлений</li>
              <li>Сократить время на выполнение рутинных задач</li>
              <li>Получать бесплатные рекомендации по улучшению рекламных кампаний</li>
            </ul>
          </p>
          <h3>Стоимость решения:</h3>
          <p>Оплата за программное обеспечение составляет 10% от согласованного с клиентом рекламного бюджета</p>
          <h3>Файлы и документы:</h3>
          <List
            itemLayout="horizontal"
            bordered
            dataSource={data}
            renderItem={renderItem}
          />
        </div>
      </Content>
    </Col>
  </Row>
);

export default ProductsPage;
