import React from 'react';
import { string } from 'prop-types';

import Moment from 'react-moment';
import 'moment/locale/ru';

const DateTime = ({ dateTime }) => {
  if (!dateTime) {
    return null;
  }
  if (dateTime === '1970-01-01T00:00:00+00:00') {
    return 'Не задана';
  }
  return (
    <Moment format="D MMMM YYYY в HH:mm">{dateTime}</Moment>
  );
};

DateTime.defaultProps = {
  dateTime: '',
};

DateTime.propTypes = {
  dateTime: string,
};

export default DateTime;
