import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Layout, Menu } from 'antd';
import {
  AuditOutlined,
  DeploymentUnitOutlined,
  DollarOutlined,
  LogoutOutlined,
  ProfileFilled,
  UsergroupAddOutlined,
} from '@ant-design/icons';

import { Routes } from 'routes';
import { logout, useAuth } from 'auth';
import { useClearCurrentEndUser, useCurrentEndUser } from 'containers/CurrentUserContextProvider/endUserContextProvider';

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideMenu = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isUserLogged] = useAuth();
  const currentEndUser = useCurrentEndUser();
  const clearCurrentEndUser = useClearCurrentEndUser();

  const logoutAndClear = useCallback(() => {
    clearCurrentEndUser();
    logout();
  }, [clearCurrentEndUser]);

  if (!isUserLogged) {
    return null;
  }

  return (
    <Sider
      theme="light"
      collapsible
      collapsed={isCollapsed}
      onCollapse={setIsCollapsed}
    >
      <Menu
        defaultSelectedKeys={['end_users']}
        mode="inline"
      >
        <SubMenu
          key="profile"
          icon={<ProfileFilled />}
          title="Профиль"
        >
          <Menu.Item
            key="balance"
            icon={<DollarOutlined />}
          >
            <Link to={Routes.balance}>Баланс</Link>
          </Menu.Item>
          <Menu.Item
            key="referral"
            icon={<UsergroupAddOutlined />}
          >
            <Link to={Routes.referrals}>Рефералы</Link>
          </Menu.Item>
          <Menu.Item
            key="end_users"
            icon={<DeploymentUnitOutlined />}
          >
            <Link to={Routes.endUsers}>Пользователи</Link>
          </Menu.Item>
          <Menu.Item
            key="logout"
            icon={<LogoutOutlined />}
          >
            <Link
              to={Routes.root}
              onClick={logoutAndClear}
            >
              Выйти
            </Link>
          </Menu.Item>
        </SubMenu>
        {currentEndUser
          ? (
            <SubMenu
              key="end_user"
              title={`${currentEndUser.type} ${currentEndUser.first_name} ${currentEndUser.last_name}`}
            >
              <Menu.Item
                key="accounts"
                icon={<AuditOutlined />}
              >
                <Link to={Routes.accounts}>Объявления</Link>
              </Menu.Item>
            </SubMenu>
          )
          : null}
      </Menu>
    </Sider>
  );
};

export default SideMenu;
