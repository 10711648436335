import React from 'react';
import { string, node } from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const FormWrapper = ({ customClassName, title, children }) => (
  <div className={styles.wrapper}>
    <div className={classNames(styles.standardFormContainer, customClassName)}>
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </div>
  </div>
);

FormWrapper.defaultProps = {
  customClassName: '',
  children: null,
  title: '',
};

FormWrapper.propTypes = {
  customClassName: string,
  children: node,
  title: string,
};

export default FormWrapper;
