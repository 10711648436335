import React, { useState } from 'react';
import * as propTypes from 'prop-types';

const FaqItem = ({ title, description }) => {
  const [isToggleOn, setIsToggleOn] = useState(false);

  const onClick = () => {
    setIsToggleOn(!isToggleOn);
  };

  return (
    <div>
      <div
        className={isToggleOn ? 'title opn' : 'title'}
        onClick={onClick}
      >
        {title}
      </div>
      <div className={isToggleOn ? 'description display' : 'description'}>
        {description}
      </div>
    </div>
  );
};

FaqItem.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
};

export default FaqItem;
