import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'auth';
import { Redirect, Route } from 'react-router-dom';
import { Routes } from 'routes';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ children, ...rest }) => {
  const [isUserLogged] = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) => (isUserLogged
        ? children
        : (
          <Redirect
            to={Routes.login}
            // {
            // pathname: Routes.login,
            // state: { from: location },
            // }}
          />
        )
      )}
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

PrivateRoute.defaultProps = {
  children: null,
};

export default PrivateRoute;
