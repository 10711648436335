import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Link, Redirect, useHistory, useParams,
} from 'react-router-dom';

import {
  Breadcrumb, Empty, Space, Table,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';

import { authRequest } from 'auth';
import RelatedDateTimeInfo from 'components/RelatedDateTimeInfo';
import { useHandleTableChange } from 'hooks/useHandleTableChange';
import { useFetchTableData } from 'hooks/useFetchTableData';
import { useCurrentEndUser } from 'containers/CurrentUserContextProvider/endUserContextProvider';
import { showWarningToast } from 'utils/makeToast';
import ClientJobsTable from 'components/ClientJobsTable';
import CreateClientDrawer from 'components/CreateClientDrawer';
import CreateClientButton from 'components/CreateClientButton';
import DownloadBadgeButton from 'components/DownloadBadgeButton';

const Clients = () => {
  const [lastSuccessDateTime, setLastSuccessDateTime] = useState('');
  const [account, setAccount] = useState();
  const [clients, setClients] = useState([]);
  const [balance, setBalance] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 8 });
  const [loading, setLoading] = useState(false);
  const currentEndUser = useCurrentEndUser();

  const history = useHistory();
  const { account_id: accountId } = useParams();

  const [createClientDrawerVisible, setCreateClientDrawerVisible] = useState(false);

  const showCreateClientDrawer = () => {
    authRequest(
      '/api/user/balance',
      {
        method: 'GET',
      },
      false,
      null,
    )
      .then(({ data: response = {} }) => {
        const fetchedBalance = parseInt(response.balance, 10);
        setBalance(fetchedBalance);
        if (fetchedBalance < 10000) {
          showWarningToast('Пополните баланс', `Минимальная сумма при создании кабинета 10 000 руб.
           Текущий баланс ${response.balance}.`);
          console.log(response.balance);
        } else {
          setCreateClientDrawerVisible(true);
        }
      });
  };
  const onCloseCreateClientDrawer = () => {
    setCreateClientDrawerVisible(false);
  };

  const columns = useMemo(() => ([{
    title: 'Имя',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Дн. лим.',
    dataIndex: 'day_limit',
    key: 'day_limit',
  },
  {
    title: 'Общ. лим.',
    dataIndex: 'all_limit',
    key: 'all_limit',
  }]), []);

  const updateClients = (userId, pageSize, current, isForce) => {
    authRequest(`/api/vk/accounts/${accountId}/clients?vk_user_id=${userId}&limit=${pageSize}&offset=${(current - 1) * pageSize}&is_force=${isForce}`)
      .then(({ data: response = {} }) => {
        setLoading(false);
        const {
          last_success_dt: lastSuccessDT,
          account: fetchedAccount,
          count,
          warning,
          clients: fetchedClients,
        } = response;
        setLastSuccessDateTime(lastSuccessDT);
        setClients(fetchedClients);
        setPagination({
          pageSize,
          current,
          total: count,
        });
        setAccount(fetchedAccount);
        if (warning) {
          showWarningToast(warning.message, warning.description);
        }
      });
  };

  const fetchTableData = useCallback((pageSize, current, userId) => {
    updateClients(userId, pageSize, current, 0);
  }, [accountId]);

  const handleTableChange = useHandleTableChange({ currentEndUser }, fetchTableData);
  useFetchTableData(pagination, currentEndUser, fetchTableData, setLoading);

  if (!currentEndUser) {
    return <Redirect to="" />;
  }

  const downloadBtnOnClick = useCallback((event) => {
    event.preventDefault();
    setPagination({ current: 1, pageSize: 8 });
    setLoading(true);
    updateClients(currentEndUser.id, pagination.pageSize, pagination.current, 1);
  }, []);

  if (!currentEndUser) {
    return <Redirect to="" />;
  }

  const defaultUpdateClients = useCallback(() => {
    updateClients(currentEndUser.id, pagination.pageSize, pagination.current, 1);
  }, [currentEndUser, pagination]);

  return (
    <>
      <Header className="site-layout-sub-header-background">
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>
            {' '}
            <Link to="/accounts">Рекламные кабинеты</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{account ? account.name : '...'}</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Content style={{ margin: '0 16px' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <ClientJobsTable
            account={account}
          />
          <Space>
            <CreateClientButton
              isMainAccount={account ? account.is_main : false}
              showCreateClientDrawer={showCreateClientDrawer}
            />
            <DownloadBadgeButton
              isAsyncUpdating={false}
              onClick={downloadBtnOnClick}
            />
          </Space>
          <RelatedDateTimeInfo
            message="Список клиентов агенств выгружен из ВК:"
            dateTime={lastSuccessDateTime}
          />
          <Table
            columns={columns}
            dataSource={clients}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            locale={{ emptyText: <Empty description={false} /> }}
            rowClassName="clickable-table-row"
            size="small"
            rowKey="id"
            onRow={(record) => ({
              onClick: () => {
                history.push(`/clients/${record.id}/campaigns`);
              },
            })}
          />
        </div>
        <CreateClientDrawer
          accountId={account ? account.id : -1}
          isMainAccount={account ? account.is_main : false}
          createClientDrawerVisible={createClientDrawerVisible}
          onCloseCreateClientDrawer={onCloseCreateClientDrawer}
          balance={balance}
          defaultUpdateClients={defaultUpdateClients}
        />
      </Content>
    </>
  );
};

export default Clients;
