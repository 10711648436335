import { authRequest } from 'auth';

export const makeVKAuth = async (setUser, code) => {
  const { data } = await authRequest('/api/vk/auth/by_code', {
    method: 'post',
    body: JSON.stringify({ code }),
  });

  console.log('fetch token response', data);
  setUser(data);
};
