import React, { useContext } from 'react';
import { noop } from 'utils';

export const CommonContext = React.createContext({
  currentEndUser: undefined,
  actions: {
    clearCurrentEndUser: noop,
    setCurrentEndUser: noop,
    handleCurrentEndUserAuthObsolete: noop,
  },
});

const useContextWithCheckValues = () => {
  const context = useContext(CommonContext);
  if (!context
    || context.actions.clearCurrentEndUser === noop
    || context.actions.setCurrentEndUser === noop
    || context.actions.handleCurrentEndUserAuthObsolete === noop
  ) {
    throw new Error(`You should use "useCurrentEndUser" function only inside appropriate Context.Provider
    or previously initiate context with non-default functions`);
  } else {
    return context;
  }
};

export const useCurrentEndUser = () => {
  const context = useContextWithCheckValues();
  return context.currentEndUser;
};

export const useClearCurrentEndUser = () => {
  const context = useContextWithCheckValues();
  return context.actions.clearCurrentEndUser;
};

export const useSetCurrentEndUser = () => {
  const context = useContextWithCheckValues();
  return context.actions.setCurrentEndUser;
};

export const useHandleCurrentEndUserAuthObsolete = () => {
  const context = useContextWithCheckValues();
  return context.actions.handleCurrentEndUserAuthObsolete;
};

export const useEndUserActions = () => {
  const context = useContextWithCheckValues();
  return context.actions;
};
