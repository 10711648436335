import React from 'react';
import { Content } from 'antd/es/layout/layout';
import { Descriptions } from 'antd';

const Contacts = () => (
  <Content style={{ margin: '0 16px' }}>
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Descriptions
        column={1}
        title="Контакты"
        bordered
      >
        <Descriptions.Item label="Наименование">ООО &quot;ТЕСЛА ТАРГЕТ&quot;</Descriptions.Item>
        <Descriptions.Item label="Телефон">+7 (960) 813-10-62</Descriptions.Item>
        <Descriptions.Item label="Email">TESLA.TARGET@GMAIL.COM</Descriptions.Item>
        <Descriptions.Item label="Адрес">САМАРА, УЛ. МИЧУРИНА, 21 ОФИС 311</Descriptions.Item>
        <Descriptions.Item label="ОГРН">1166313056722</Descriptions.Item>
        <Descriptions.Item label="ИНН">6315008597</Descriptions.Item>
      </Descriptions>
    </div>
  </Content>
);

export default Contacts;
