import React, {
  useCallback, useMemo, useState,
} from 'react';
import { authRequest } from 'auth';
import Content, { Header } from 'antd/es/layout/layout';
import {
  Breadcrumb, Button, Card, Col, Empty, Statistic, Table, Row,
} from 'antd';
import {
  ArrowUpOutlined,
  CheckOutlined,
  InfoCircleOutlined,
  LeftSquareFilled,
  RightSquareFilled,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { showWarningToast } from 'utils/makeToast';
import { useHandleTableChange } from 'hooks/useHandleTableChange';
import { useFetchTableData } from 'hooks/useFetchTableData';
import { Routes } from 'routes';
import { useCurrentEndUser } from 'containers/CurrentUserContextProvider/endUserContextProvider';
import DateTime from 'components/DateTime';
import AddBalanceProfileDrawer from 'components/AddBalanceProfileDrawer';

const Balance = () => {
  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 8 });
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState('...');
  const [profit, setProfit] = useState();
  const currentEndUser = useCurrentEndUser();
  const [addBalanceProfileDrawerVisible, setAddBalanceProfileDrawerVisible] = useState(false);

  const showAddBalanceProfileDrawer = () => {
    setAddBalanceProfileDrawerVisible(true);
  };

  const onCloseAddBalanceProfileDrawer = () => {
    setAddBalanceProfileDrawerVisible(false);
  };

  const getTypeComponent = (text) => (text === 'deposit'
    ? (
      <>
        <LeftSquareFilled style={{ margin: '0 16px 0 0' }} />
        {' '}
        <span> Пополнение </span>
      </>
    )
    : (
      <>
        <RightSquareFilled style={{ margin: '0 16px 0 0' }} />
        {' '}
        <span> Вывод </span>
      </>
    ));

  const getStatusComponent = (text) => (text === 'cancelled'
    ? (
      <>
        <InfoCircleOutlined style={{ margin: '0 16px 0 0' }} />
        {' '}
        <span> Отмена</span>
      </>
    )
    : (
      <>
        <CheckOutlined style={{ margin: '0 16px 0 0' }} />
        {' '}
        <span> Успешно</span>
      </>
    ));

  const columns = useMemo(() => ([{
    title: 'Тип',
    dataIndex: 'type',
    render: (text) => getTypeComponent(text),
    key: 'type',
  }, {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description',
  }, {
    title: 'Сумма',
    dataIndex: 'payment',
    key: 'payment',
  }, {
    title: 'Внесено',
    dataIndex: 'payment_taken',
    key: 'payment_taken',
  }, {
    title: 'Экономия',
    dataIndex: 'profit',
    key: 'profit',
  }, {
    title: 'Процент',
    dataIndex: 'percentage',
    key: 'percentage',
    render: (text) => (text ? `${text} %` : ''),
  }, {
    title: 'Статус',
    dataIndex: 'status',
    render: (text) => getStatusComponent(text),
    key: 'type',
  }, {
    title: 'Дата',
    dataIndex: 'create_at',
    render: (text) => (<DateTime dateTime={text} />),
    key: 'create_at',
  }]), []);

  const fetchTableData = useCallback((pageSize, current, userId) => {
    authRequest(
      `/api/user/transactions?limit=${pageSize}&offset=${(current - 1) * pageSize}`,
      null,
      false,
      null,
    )
      .then(({ data: response = {} }) => {
        setLoading(false);
        const {
          transactions: fetchedTransactions,
          balance: fetchedBalance,
          profit: fetchedProfit,
          count,
          warning,
        } = response;
        setTransactions(fetchedTransactions);
        setPagination({
          pageSize,
          current,
          total: count,
        });
        setBalance(fetchedBalance);
        setProfit(fetchedProfit);
        if (warning) {
          showWarningToast(warning.message, warning.description);
        }
      });
  }, []);

  const handleTableChange = useHandleTableChange(null, fetchTableData);
  useFetchTableData(pagination, currentEndUser, fetchTableData, setLoading);

  return (
    <>
      <Header className="site-layout-sub-header-background">
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>
            {' '}
            <Link to={Routes.balance}>Баланс</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Content style={{ margin: '0 16px' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Card>
                <Statistic
                  title="Баланс (руб)"
                  value={balance}
                  precision={2}
                />
              </Card>
            </Col>
            {profit > 0 ? (
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Сэкономлено с нами"
                    value={profit}
                    precision={2}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={<ArrowUpOutlined />}
                    suffix="руб"
                  />
                </Card>
              </Col>
            ) : ''}

          </Row>
          <Button
            style={{ marginTop: 16 }}
            type="primary"
            onClick={showAddBalanceProfileDrawer}
          >
            Пополнить
          </Button>
          <Table
            columns={columns}
            dataSource={transactions}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            locale={{ emptyText: <Empty description={false} /> }}
            rowClassName="clickable-table-row"
            size="small"
          />
        </div>
        <AddBalanceProfileDrawer
          addBalanceProfileDrawerVisible={addBalanceProfileDrawerVisible}
          onCloseAddBalanceProfileDrawer={onCloseAddBalanceProfileDrawer}
        />
      </Content>
    </>
  );
};

export default Balance;
