import React, { useEffect, useState, useCallback } from 'react';
import {
  Card, Button, Divider, Spin, Alert, List,
} from 'antd';

import Content from 'antd/es/layout/layout';
import { PlusOutlined } from '@ant-design/icons';
import { authRequest } from 'auth';

import UserAccountCard from 'components/UserAccountCard';
import { useCurrentEndUser, useSetCurrentEndUser } from 'containers/CurrentUserContextProvider/endUserContextProvider';
import { showSuccessToast } from 'utils/makeToast';

const { Meta } = Card;

const UserAccounts = () => {
  const [endUsers, setEndUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [isShowResendLink, setIsShowResendLink] = useState(true);
  const currentEndUser = useCurrentEndUser();
  const setCurrentEndUser = useSetCurrentEndUser();

  const fetchTargetAccountsData = useCallback(() => {
    authRequest('/api/end_users')
      .then(({ data: response = {} }) => {
        setEndUsers(response.end_users);
      });
  }, []);

  const newAccountBtnOnClick = useCallback((e) => {
    e.preventDefault();
    authRequest('/api/vk/auth/url')
      .then(({ data: response = {} }) => {
        window.location.href = response.authorize_url;
      });
  }, []);

  const resendEmailBtnOnClick = useCallback((e) => {
    e.preventDefault();
    authRequest(
      '/api/resend',
      {
        method: 'POST',
      },
    )
      .then(({ data: response = {} }) => {
        // TODO: why I can't do ?
        // showSuccessToast(`Письмо отправлено на почту ${currentUser.email}`);
        showSuccessToast('Письмо отправлено');
        setIsShowResendLink(false);
      });
  }, []);

  const generateCardOnClickHandler = useCallback((account) => (e) => {
    e.preventDefault();
    setCurrentEndUser(account);
  }, [setCurrentEndUser]);

  useEffect(() => {
    authRequest('/api/user/profile')
      .then(({ data: response = {} }) => {
        setCurrentUser(response);
        if (response.is_confirmed) {
          fetchTargetAccountsData();
        }
      });
  }, [currentEndUser, fetchTargetAccountsData]);

  if (!currentUser) {
    return <Spin />;
  }
  if (currentUser && !currentUser.is_confirmed) {
    return (
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Alert
          message="Подтверждение почты"
          description={`Подтвердите почту. Письмо отправлено на адрес ${currentUser.email}`}
          type="error"
        />
        { isShowResendLink
          ? (
            <Button
              type="link"
              onClick={resendEmailBtnOnClick}
            >
              Отправить повторно
            </Button>
          )
          : null }
      </div>
    );
  }

  return (
    <Content style={{ margin: '0 16px' }}>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Card
          style={{ width: 300 }}
          actions={[
            <Button
              icon={<PlusOutlined />}
              onClick={newAccountBtnOnClick}
            />,
          ]}
        >
          <Meta
            title="Новый аккаунт VK"
            description="..."
          />
        </Card>
        <Divider />

        <List
          grid={{ gutter: 100, column: 3 }}
          dataSource={endUsers}
          renderItem={(account) => (
            <List.Item>
              <UserAccountCard
                key={account.id}
                handleClick={generateCardOnClickHandler(account)}
                isActive={currentEndUser ? account.id === currentEndUser?.id : false}
                isExpired={account.is_expired}
                avatarSrc={account.profile_photo_url}
                title={account.type}
                description={`${account.first_name} ${account.last_name}`}
              />
            </List.Item>
          )}
        />

      </div>
    </Content>
  );
};

export default UserAccounts;
