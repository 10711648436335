import { showErrorToast } from 'utils/makeToast';

export const getResponseHeaders = (responseHeaders) => {
  const parsedHeaders = {};

  if (!responseHeaders || !responseHeaders.entries().length) {
    return {};
  }
  responseHeaders.entries().forEach(([key, value]) => {
    parsedHeaders[key] = value;
  });
  return parsedHeaders;
};

export const generateCommonRequestError = async (response, fallbacksMsg, withHeaders = false) => {
  const preaparedErrorResponse = {
    ...(withHeaders ? { headers: getResponseHeaders(response.headers) } : {}),
    error: {
      status: response.status,
    },
  };

  try {
    const parsedResponse = await response.json();

    preaparedErrorResponse.error = {
      ...preaparedErrorResponse.error,
      message: parsedResponse.message || response.statusText || fallbacksMsg,
      details: parsedResponse.error ? parsedResponse.error : parsedResponse,
    };
  } catch (error) {
    preaparedErrorResponse.error = {
      ...preaparedErrorResponse.error,
      message: response.statusText || fallbacksMsg,
    };
  }

  showErrorToast(`An error Occurred with status ${preaparedErrorResponse.error.status}`, preaparedErrorResponse.error.message);
  return Promise.reject(preaparedErrorResponse);
};

export const prepareRequestOptions = (options) => {
  if (!options) {
    return undefined;
  }

  const { body } = options;
  let stringifiedBody = body;
  if (typeof body !== 'string') {
    stringifiedBody = JSON.stringify(body);
  }

  return {
    ...options,
    ...(body ? { body: stringifiedBody } : {}),
  };
};

export const makeInvokeFuncByErrorCode = (errorCodes, errorHandlers) => (
  errorCodes.reduce((acc, errorCode, index) => {
    if (errorHandlers[index] && typeof errorHandlers[index] === 'function') {
      acc[errorCode] = errorHandlers[index];
    }
    return acc;
  }, {})
);
