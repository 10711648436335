import React from 'react';
import {
  oneOfType,
  shape,
  string,
  number,
} from 'prop-types';

import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const BreadcrumbAccountItem = ({ account }) => {
  if (!account) {
    return null;
  }
  if (account.type !== 'agency') {
    return null;
  }

  return (
    <Breadcrumb.Item>
      <Link to={`/accounts/${account.id}/clients`}>
        {account?.name || '...'}
      </Link>
    </Breadcrumb.Item>
  );
};

BreadcrumbAccountItem.defaultProps = {
  account: undefined,
};

BreadcrumbAccountItem.propTypes = {
  account: shape({
    type: string.isRequired,
    name: string,
    id: oneOfType([number, string]).isRequired,
  }),
};

export default BreadcrumbAccountItem;
