import React from 'react';

import { bool, func } from 'prop-types';
import * as propTypes from 'prop-types';
import AddBalanceClientDrawer from 'components/EditClientDrawers/AddBalanceClientDrawer';
import AssignClientDrawer from 'components/EditClientDrawers/AssignClientDrawer';
import RenameClientDrawer from 'components/EditClientDrawers/RenameClientDrawer';

const EditCampaignDrawers = ({
  isMainAccount,
  clientName,
  clientId,
  balance,
  referralBalance,
  minPaymentAmountTopUp,
  minPaymentReferralAmountTopUp,
  addBalanceDrawerVisible,
  onCloseAddBalanceDrawer,
  assignDrawerVisible,
  onCloseAssignDrawer,
  renameDrawerVisible,
  onCloseRenameDrawer,
  updateClientName,
}) => {
  if (!isMainAccount) {
    return null;
  }

  return (
    <>
      <AddBalanceClientDrawer
        clientId={clientId}
        addBalanceDrawerVisible={addBalanceDrawerVisible}
        onCloseAddBalanceDrawer={onCloseAddBalanceDrawer}
        balance={balance}
        referralBalance={referralBalance}
        minPaymentAmountTopUp={minPaymentAmountTopUp}
        minPaymentReferralAmountTopUp={minPaymentReferralAmountTopUp}
      />
      <AssignClientDrawer
        clientId={clientId}
        assignDrawerVisible={assignDrawerVisible}
        onCloseAssignDrawer={onCloseAssignDrawer}
      />
      <RenameClientDrawer
        clientId={clientId}
        renameDrawerVisible={renameDrawerVisible}
        onCloseRenameDrawer={onCloseRenameDrawer}
        clientName={clientName}
        updateClientName={updateClientName}
      />
    </>
  );
};

EditCampaignDrawers.propTypes = {
  isMainAccount: bool.isRequired,
  clientName: propTypes.string.isRequired,
  clientId: propTypes.string.isRequired,
  balance: propTypes.number.isRequired,
  referralBalance: propTypes.number.isRequired,
  minPaymentAmountTopUp: propTypes.number.isRequired,
  minPaymentReferralAmountTopUp: propTypes.number.isRequired,
  addBalanceDrawerVisible: bool.isRequired,
  onCloseAddBalanceDrawer: func.isRequired,
  assignDrawerVisible: bool.isRequired,
  onCloseAssignDrawer: func.isRequired,
  renameDrawerVisible: bool.isRequired,
  onCloseRenameDrawer: func.isRequired,
  updateClientName: func.isRequired,
};

export default EditCampaignDrawers;
