import React, { useCallback, useEffect } from 'react';
import FaqItem from 'pages/Landing/components/FaqItem';
import { Button, Form, Input } from 'antd';
import { request } from 'auth';
import { showInfoToast } from 'utils/makeToast';
import { Routes } from 'routes';
import styles from './css/style.css'; // it's important import. Don't remove
import logo from './img/logo.png';
import screen1 from './img/screen1.png';
import vk from './img/vk.png';
import my from './img/my.png';
import tt from './img/tt.png';
import ya from './img/ya.png';
import ico1 from './img/ico1.png';
import ico2 from './img/ico2.png';
import ico3 from './img/ico3.png';
import screen2 from './img/screen2.png';

export const Landing = () => {
  const onFinish = useCallback((form) => {
    request('/api/get_call', {
      method: 'post',
      body: JSON.stringify(form),
    })
      .then(({ data }) => {
        showInfoToast('Запрос отправлен');
      });
  }, []);

  return (
    <div className="landing-layout">
      <div className="wrapper">
        <div className="header">
          <img
            src={logo}
            alt=""
          />
          <a
            href={Routes.products}
            className="products"
          >
            Наши продукты
          </a>
          <a
            href={Routes.login}
            className="enter"
          >
            Вход
          </a>
        </div>
        <div className="topblock faded active">
          <div>
            <div className="title">Оптимизация рекламы с выгодой до 25% от суммы расходов</div>
            <div className="description">Выводите кэшбэк от рекламы любым удобным способом без комиссии и анализируйте показатели</div>
            <div className="buttons">
              <a
                href={Routes.registration}
                className="redbtn"
              >
                Регистрация
              </a>
              <a
                href={Routes.login}
                className="whitebtn"
              >
                Войти
              </a>
            </div>
          </div>
          <div className="image">
            <img
              src={screen1}
              alt=""
            />
          </div>
        </div>

        <div className="networks">
          <div className="title">РАБОТАЕТ С РЕКЛАМНЫМИ КАМПАНИЯМИ</div>
          <div className="images">
            <div>
              <img
                src={vk}
                alt=""
              />
            </div>
            <div>
              <img
                src={my}
                alt=""
              />
            </div>
            <div>
              <img
                src={tt}
                alt=""
              />
            </div>
            <div>
              <img
                src={ya}
                alt=""
              />
            </div>
          </div>
        </div>
        <h2 className="faded active">Кому подойдет?</h2>
        <div className="col3">
          <div className="cart active">
            <img
              src={ico1}
              alt=""
            />
            <div className="title">Фрилансерам</div>
            <div className="description">
              Получайте выгоду до 25% от каждого пополнения клиента, проходите модерацию быстрее, сократите время на рутинные
              задачи по проектам, используя автостратегии и получайте развернутую аналитику в одном окне
            </div>
          </div>
          <div className="cart active">
            <img
              src={ico2}
              alt=""
            />
            <div className="title">Маркетологам</div>
            <div className="description">
              Оптимизируйте рекламные показатели: CРС, CPA, ROMI с выгодой до 25% и формируйте отчетность одним кликом! Теперь KPI
              достигнуть гораздо проще!
            </div>
          </div>
          <div className="cart active">
            <img
              src={ico3}
              alt=""
            />
            <div className="title">Предпринимателям</div>
            <div className="description">
              Привлекайте клиентов в собственный бизнес на 25% дешевле, чем конкуренты, контролируйте рекламные активности через
              удобные дашборды - вся аналитика по проекту на одном экране!
            </div>
          </div>
        </div>
      </div>

      <div className="screen2 active">
        <div className="wrapper">
          <div className="block2 faded active">
            <div>
              <div className="title">Тратьте меньше - получайте больше!</div>
              <div className="description">
                Упростите работу с интернет-рекламой
                <br />
                с помощью сервиса
                <b>
                  target.
                  <span>sexy</span>
                </b>
              </div>
              <div className="buttons">
                <a
                  href={Routes.registration}
                  className="redbtn"
                >
                  Регистрация
                </a>
              </div>
            </div>
            <div className="image">
              <img
                src={screen2}
                alt=""
              />
            </div>
          </div>
          <div className="col3">
            <div className="cart active">
              <div className="title">Официально</div>
              <div className="description">
                Получайте закрывающие
                <br />
                документы (для юридических лиц и ИП)
              </div>
            </div>
            <div className="cart active">
              <div className="title">Моментально</div>
              <div className="description">Запуск рекламы в любой момент</div>
            </div>
            <div className="cart active">
              <div className="title">Прозрачно</div>
              <div className="description">
                Наглядные дашборды с
                <br />
                резуьтатами рекламы
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="wrapper">
        <h2 className="faded active">Чем полезен наш сервис?</h2>
        <div className="benefits">
          <div className="active">
            Доступный порог входа -
            <br />
            начать можно с бюджета
            <br />
            в десять тысяч рублей
          </div>
          <div className="active">
            Удобная для чтения
            <br />
            аналитика - интерактивный
            <br />
            дашборд
          </div>
          <div className="active">
            Бесплатное обучение,
            <br />
            базы знаний, вебинары
            <br />
            и видеоуроки
          </div>
          <div className="active">
            Бесплатные рекомендации
            <br />
            по улучшению рекламных
            <br />
            кампаний
          </div>
        </div>
        <div className="button">
          <a
            href={Routes.registration}
            className="bluebtn"
          >
            Зарегистрироваться
          </a>
        </div>

      </div>

      {/*
      <div className="wrapper">
        <div className="calcformblock">
          <h2 className="faded active">Рассчитайте ваш бонус</h2>
          <div className="description">Просто введите сумму ежемесячного рекламного бюджета</div>
          <form className="calcform">
            <div>
              <input
                type="text"
                className="text vk"
                placeholder="Введите сумму"
              />
            </div>
            <div>
              <input
                type="text"
                className="text my"
                placeholder="Введите сумму"
              />
            </div>
            <div />
            <div>
              <input
                type="text"
                className="text tt"
                placeholder="Введите сумму"
              />
            </div>
            <div>
              <input
                type="text"
                className="text ya"
                placeholder="Введите сумму"
              />
            </div>
            <div>
              <input
                type="submit"
                className="redbtn"
                value="Рассчитать"
              />
            </div>
          </form>
          <div className="total">
            <div className="bonus">
              Ваш бонус:
              <div className="red"> 54 000 руб./ месяц</div>
            </div>
            <div className="little">Ежедневная выплата вознаграждения на банковскую карту</div>
          </div>
        </div>
      </div>
      */}
      <div className="numberline">
        <div className="wrapper">
          <div>
            <div className="title">Зарегистрируйтесь</div>
            <div className="description">
              Пройдите регистрацию и
              <br />
              подтвердите электронную почту
            </div>
          </div>
          <div>
            <div className="title">Пополните баланс</div>
            <div className="description">
              Оплатите рекламу любым удобным
              <br />
              способом на сумму от 10 000 рублей
            </div>
          </div>
          <div>
            <div className="title">
              Продвигайтесь
              <br />
              с выгодой до 25%
            </div>
            <div className="description">
              Выводите кэшбэк любым удобным
              <br />
              способом без комисcии и
              <br />
              анализируйте показатели
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper">
        <div className="questions">
          <div>
            <h2 className="faded active">Вопросы</h2>
            <div className="faq">
              <FaqItem
                title="У меня много проектов! Сколько рекламных кабинетов я могу получить?"
                description="Мы выделяем 1 рекламный кабинет на каждые 150 тысяч бюджета"
              />
              <FaqItem
                title="Для регистрации рекламного кабинета обязательно иметь юридическое лицо?"
                description="Нет! Можно оплачивать переводом по договору оферты"
              />
              <FaqItem
                title="На какую минимальную сумму можно пополнить рекламный кабинет?"
                description="Мы принимаем оплаты от 10000 рублей"
              />
            </div>
          </div>

          <div>
            <div className="questionform">
              <div className="title">Не нашли ответ на свой вопрос?</div>
              <div className="description">Оставьте заявку - мы перезвоним и ответим на все Ваши вопросы</div>

              <Form
                onFinish={onFinish}
              >
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Введите Имя', max: 48 }]}
                >
                  <Input
                    className="text"
                    placeholder="Имя"
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[{ required: true, message: 'Введите Телефон. Не более 12 цифр.', max: 12 }]}
                >
                  <Input
                    className="text"
                    placeholder="Телефон"
                  />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="bluebtn"
                  >
                    Заказать звонок
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>

        </div>
      </div>

      <div className="footer">
        <div className="wrapper">
          <div>
            <img
              src={logo}
              alt=""
            />
          </div>
          <div>
            © Target.sexy
            <br />
            Все права защищены
          </div>
          {/* <div> */}
          {/*  <a */}
          {/*    href="ya.ru" */}
          {/*    className="under" */}
          {/*  > */}
          {/*    Политика конфиденциальности */}
          {/*  </a> */}
          {/* </div> */}
          {/* <div className="phone">+7 903 302 45 60</div> */}
          <div>
            <a
              href={Routes.registration}
              className="redbtn"
            >
              Регистрация
            </a>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Landing;
