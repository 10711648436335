import React, {
  useCallback, useMemo, useState,
} from 'react';
import { authRequest } from 'auth';
import Content, { Header } from 'antd/es/layout/layout';
import {
  Breadcrumb, Card, Col, Empty, Statistic, Table, Row, Badge, message, Button, Space,
} from 'antd';
import {
  UserOutlined,
  CheckOutlined,
  InfoCircleOutlined,
  LeftSquareFilled,
  RightSquareFilled,
  CopyOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { showWarningToast } from 'utils/makeToast';
import { useHandleTableChange } from 'hooks/useHandleTableChange';
import { useFetchTableData } from 'hooks/useFetchTableData';
import { Routes } from 'routes';
import { useCurrentEndUser } from 'containers/CurrentUserContextProvider/endUserContextProvider';
import DateTime from 'components/DateTime';
import Text from 'antd/es/typography/Text';

const Referrals = () => {
  const [referralTransactions, setReferralTransactions] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 8 });
  const [loading, setLoading] = useState(false);
  const [referralBalance, setReferralBalance] = useState('...');
  const [referralUsers, setReferralUsers] = useState([]);
  const currentEndUser = useCurrentEndUser();

  const getTypeComponent = (text) => (text === 'deposit'
    ? (
      <>
        <LeftSquareFilled style={{ margin: '0 16px 0 0' }} />
        {' '}
        <span> Пополнение </span>
      </>
    )
    : (
      <>
        <RightSquareFilled style={{ margin: '0 16px 0 0' }} />
        {' '}
        <span> Вывод </span>
      </>
    ));

  const getStatusComponent = (text) => (text === 'cancelled'
    ? (
      <>
        <InfoCircleOutlined style={{ margin: '0 16px 0 0' }} />
        {' '}
        <span> Отмена</span>
      </>
    )
    : (
      <>
        <CheckOutlined style={{ margin: '0 16px 0 0' }} />
        {' '}
        <span> Успешно</span>
      </>
    ));

  const onClickGetLink = useCallback((e) => {
    e.preventDefault();
    authRequest('/api/user/profile')
      .then(({ data: response = {} }) => {
        const referralLink = `${window.location.protocol}//${window.location.host}/registration?refererUserId=${response.id}`;
        navigator.clipboard.writeText(referralLink);
        message.success('Ссылка скопирована');
        message.success(referralLink);
      });
  }, []);

  const columns = useMemo(() => ([{
    title: 'Тип',
    dataIndex: 'type',
    render: (text) => getTypeComponent(text),
    key: 'type',
  }, {
    title: 'Реферал',
    dataIndex: 'referral_user',
    key: 'referral_user',
    render: (text, record) => (record.referral_user ? `${record.referral_user.first_name} ${record.referral_user.last_name}` : ''),
  }, {
    title: 'Процент',
    dataIndex: 'percentage',
    key: 'percentage',
    render: (text) => (text ? `${text} %` : ''),
  }, {
    title: 'Сумма',
    dataIndex: 'payment',
    key: 'payment',
  }, {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description',
  }, {
    title: 'Статус',
    dataIndex: 'status',
    render: (text) => getStatusComponent(text),
    key: 'type',
  }, {
    title: 'Дата',
    dataIndex: 'create_at',
    render: (text) => (<DateTime dateTime={text} />),
    key: 'create_at',
  }]), []);

  const fetchTableData = useCallback((pageSize, current, userId) => {
    authRequest(
      `/api/user/referral_transactions?limit=${pageSize}&offset=${(current - 1) * pageSize}`,
      null,
      false,
      null,
    )
      .then(({ data: response = {} }) => {
        setLoading(false);
        const {
          referral_transactions: fetchedReferralTransactions,
          referral_balance: fetchedReferralBalance,
          referral_users: fetchedReferralUsers,
          count,
          warning,
        } = response;
        setReferralTransactions(fetchedReferralTransactions);
        setPagination({
          pageSize,
          current,
          total: count,
        });
        setReferralBalance(fetchedReferralBalance);
        setReferralUsers(fetchedReferralUsers);
        if (warning) {
          showWarningToast(warning.message, warning.description);
        }
      });
  }, []);

  const handleTableChange = useHandleTableChange(null, fetchTableData);
  useFetchTableData(pagination, currentEndUser, fetchTableData, setLoading);

  return (
    <>
      <Header className="site-layout-sub-header-background">
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>
            {' '}
            <Link to={Routes.referrals}>Рефералы</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Content style={{ margin: '0 16px' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <div className="mb20">
            <Badge.Ribbon
              text="Зарабатывайте с нами"
            >
              <Card
                title="Реферальная программа"
                size="small"
              >
                <Space direction="vertical">
                  <Text>
                    Приглашайте друзей и коллег и получайте часть их пополнений на свой реферальный баланс
                  </Text>
                  <Button
                    icon={<CopyOutlined />}
                    type="dashed"
                    onClick={onClickGetLink}
                  >
                    Персональная ссылка
                  </Button>
                </Space>
              </Card>
            </Badge.Ribbon>
          </div>
          <Row
            gutter={16}
            className="mb20"
          >
            <Col span={12}>
              <Card>
                <Statistic
                  title="Реферальный Баланс (руб)"
                  value={referralBalance}
                  precision={2}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <Statistic
                  title="Зарегистрировано по вашей ссылке"
                  prefix={<UserOutlined />}
                  value={referralUsers.length}
                  valueStyle={{ color: '#3f8600' }}
                  precision={0}
                />
              </Card>
            </Col>

          </Row>
          <Table
            columns={columns}
            dataSource={referralTransactions}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            locale={{ emptyText: <Empty description={false} /> }}
            rowClassName="clickable-table-row"
            size="small"
          />
        </div>
      </Content>
    </>
  );
};

export default Referrals;
