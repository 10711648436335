import React from 'react';
import { Content } from 'antd/es/layout/layout';
import { Routes } from 'routes';
import { Link } from 'react-router-dom';
import { Result } from 'antd';

const NotFoundPage = () => (
  <Content>
    <Result
      status="404"
      title="404"
      subTitle="Страница не найдена"
      extra={<Link to={Routes.login}> Перейти на главную </Link>}
    />
  </Content>
);

export default NotFoundPage;
