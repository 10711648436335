import React from 'react';
import Text from 'antd/es/typography/Text';
import { Link } from 'react-router-dom';
import { Routes } from 'routes';
import * as propTypes from 'prop-types';
import { Space } from 'antd';

const AddFromReferralBalanceExtra = ({ referralBalance, minPaymentReferralAmountTopUp }) => (
  <Space direction="vertical">
    <Text>{`Доступно по реферальной  программе ${referralBalance} руб`}</Text>
    <Text>{`Минимальная сумма ${minPaymentReferralAmountTopUp}`}</Text>
    <Link to={Routes.referrals}> Рефералы </Link>
  </Space>
);

AddFromReferralBalanceExtra.propTypes = {
  referralBalance: propTypes.number.isRequired,
  minPaymentReferralAmountTopUp: propTypes.number.isRequired,
};

export default AddFromReferralBalanceExtra;
