import React from 'react';

import {
  Button, Drawer, Form, Input, InputNumber,
} from 'antd';

import { bool, func } from 'prop-types';
import { Link } from 'react-router-dom';
import { Routes } from 'routes';
import { authRequest } from 'auth';
import { useCurrentEndUser } from 'containers/CurrentUserContextProvider/endUserContextProvider';
import * as propTypes from 'prop-types';

const CreateClientDrawer = ({
  accountId,
  isMainAccount,
  createClientDrawerVisible,
  onCloseCreateClientDrawer,
  balance,
  defaultUpdateClients,
}) => {
  if (!isMainAccount) {
    return null;
  }
  const currentEndUser = useCurrentEndUser();
  const minPaymentAmountCreate = 5000;

  const handleFormSubmit = (values) => {
    console.log(values);
    authRequest(
      `/api/vk/clients?vk_user_id=${currentEndUser.id}`,
      {
        method: 'POST',
        body: JSON.stringify({
          account_id: accountId,
          name: values.name,
          payment: values.payment,
        }),
      },
      false,
      null,
    )
      .then(() => {
        // because backend worker doesn't find new task simultaneously
        setTimeout(defaultUpdateClients, 2000);
      });
    onCloseCreateClientDrawer();
  };

  return (
    <Drawer
      title="Создание клиента"
      placement="right"
      width={500}
      visible={createClientDrawerVisible}
      onClose={onCloseCreateClientDrawer}
    >
      <Form
        onFinish={(values) => handleFormSubmit(values)}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          payment: minPaymentAmountCreate,
        }}
      >
        <Form.Item
          name="name"
          label="Название"
          rules={[{
            required: true, message: 'От 3 до 60 символов', max: 60, min: 3,
          }]}
        >
          <Input placeholder="Введите название" />
        </Form.Item>
        <Form.Item
          label="Сумма, руб"
          name="payment"
          rules={[{ required: true, message: 'Введите сумму' }]}
          extra={`Доступно ${balance}`}
        >
          <InputNumber
            min={minPaymentAmountCreate}
            max={balance}
          />
        </Form.Item>
        <Link
          to={Routes.balance}
        >
          Пополнить баланс профиля
        </Link>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

CreateClientDrawer.propTypes = {
  accountId: propTypes.number.isRequired,
  isMainAccount: bool.isRequired,
  createClientDrawerVisible: bool.isRequired,
  onCloseCreateClientDrawer: func.isRequired,
  balance: propTypes.number.isRequired,
  defaultUpdateClients: func.isRequired,
};

export default CreateClientDrawer;
