import React from 'react';

import {
  Button,
  Drawer, Form, Input,
} from 'antd';

import { bool, func } from 'prop-types';
import { authRequest } from 'auth';
import { useCurrentEndUser } from 'containers/CurrentUserContextProvider/endUserContextProvider';
import * as propTypes from 'prop-types';

const RenameClientDrawer = ({
  clientId,
  renameDrawerVisible,
  onCloseRenameDrawer,
  clientName,
  updateClientName,
}) => {
  const currentEndUser = useCurrentEndUser();

  const renameFormSubmit = (values) => {
    console.log(values);
    authRequest(
      `/api/vk/clients/${clientId}?vk_user_id=${currentEndUser.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          name: values.name,
        }),
      },
      false,
      null,
    )
      .then(({ data: response = {} }) => {
        updateClientName(values.name);
      });
    onCloseRenameDrawer();
  };

  return (
    <Drawer
      title="Переименовать кабинет"
      placement="right"
      width={500}
      visible={renameDrawerVisible}
      onClose={onCloseRenameDrawer}
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={(values) => renameFormSubmit(values)}
        initialValues={{
          name: clientName,
        }}
      >
        <Form.Item
          name="name"
          label="Название"
          rules={[{ required: true, message: 'Введите название' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            Переименовать
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

RenameClientDrawer.propTypes = {
  clientId: propTypes.string.isRequired,
  renameDrawerVisible: bool.isRequired,
  onCloseRenameDrawer: func.isRequired,
  clientName: propTypes.string.isRequired,
  updateClientName: func.isRequired,
};

export default RenameClientDrawer;
