import React from 'react';
import { Content } from 'antd/es/layout/layout';
import { Routes } from 'routes';
import { Link } from 'react-router-dom';
import { Result } from 'antd';

const PaymentSuccessPage = () => (
  <Content>
    <Result
      status="success"
      title="Платёж успешен"
      subTitle="Учёт платежа в личном кабинете может произойти не сразу. Платеж учтется в течении 1 - 5 минут. Спасибо за понимание."
      extra={<Link to={Routes.balance}> Перейти к балансу </Link>}
    />
  </Content>
);

export default PaymentSuccessPage;
