import React, { useCallback } from 'react';

import {
  Button, Collapse, Divider, Drawer, Form, InputNumber, message, Space,
} from 'antd';

import { bool, func } from 'prop-types';
import { authRequest } from 'auth';
import paymentLogos from 'assets/payment_system_logos.png';
import Text from 'antd/es/typography/Text';
import { CopyOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const InfoParagraph = () => (
  <p>
    <span>Для пополнения баланса через через оплату на </span>
    <br />
    <span> расчетный счет напишите нам на почту </span>
    <a
      href="mailto:target.technologies@yandex.ru"
    >
      target.technologies@yandex.ru
    </a>
  </p>
);

const AddBalanceProfileDrawer = ({
  addBalanceProfileDrawerVisible,
  onCloseAddBalanceProfileDrawer,
}) => {
  const onClickGetMyData = useCallback((e) => {
    e.preventDefault();
    authRequest('/api/user/profile')
      .then(({ data: response = {} }) => {
        const userData = `${response.id}\n${response.email}\n${response.first_name}\n${response.last_name}`;
        navigator.clipboard.writeText(userData);
        message.success('Данные скопированы');
        message.success(userData);
      });
  }, []);

  const addBalanceProfileFormSubmit = (values) => {
    console.log(values);
    authRequest(
      '/api/payment/init/url',
      {
        method: 'POST',
        body: JSON.stringify({
          payment_amount: values.payment_amount,
        }),
      },
      false,
      null,
    )
      .then(({ data: response = {} }) => {
        if (response) {
          window.location.href = response.payment_init_url;
        }
      });
    onCloseAddBalanceProfileDrawer();
  };

  return (
    <Drawer
      title="Пополнить баланс профиля"
      placement="right"
      width={500}
      visible={addBalanceProfileDrawerVisible}
      onClose={onCloseAddBalanceProfileDrawer}
    >
      <Button
        icon={<CopyOutlined />}
        type="dashed"
        onClick={onClickGetMyData}
      >
        Скопировать мои данные для менеджера
      </Button>
      <Divider orientation="left">Варианты пополнения</Divider>
      <Collapse ghost>
        <Panel
          header="15% выгоды. Оплата на расчетный счет"
          key="1"
        >
          <InfoParagraph />
        </Panel>
        <Panel
          header="12% выгоды. Оплата на расчетный счет"
          key="2"
        >
          <InfoParagraph />
        </Panel>
        <Panel
          header="10% выгоды. Оплата на расчетный счет"
          key="3"
        >
          <InfoParagraph />
        </Panel>
      </Collapse>
    </Drawer>
  );
};

AddBalanceProfileDrawer.propTypes = {
  addBalanceProfileDrawerVisible: bool.isRequired,
  onCloseAddBalanceProfileDrawer: func.isRequired,
};

export default AddBalanceProfileDrawer;
