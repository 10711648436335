import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Routes } from 'routes';

import { CommonContext } from './endUserContextProvider';

const CurrentUserContextProvider = ({ children }) => {
  const [currentEndUser, setCurrentEndUser] = useState(JSON.parse(localStorage.getItem('currentEndUser')));
  const history = useHistory();

  const setCurrentEndUserLocalStorage = useCallback((endUser) => {
    localStorage.setItem('currentEndUser', JSON.stringify(endUser));
    setCurrentEndUser(endUser);
  });

  const clearCurrentEndUser = useCallback(() => {
    setCurrentEndUserLocalStorage(null);
  }, [setCurrentEndUser]);

  const handleCurrentEndUserAuthObsolete = useCallback(() => {
    setCurrentEndUserLocalStorage(null);
    history.push(Routes.endUsers);
  }, [history, setCurrentEndUser]);

  const contextValue = useMemo(() => ({
    currentEndUser,
    actions: {
      clearCurrentEndUser,
      setCurrentEndUser: setCurrentEndUserLocalStorage,
      handleCurrentEndUserAuthObsolete,
    },
  }), [clearCurrentEndUser, currentEndUser, handleCurrentEndUserAuthObsolete]);

  return (
    <CommonContext.Provider value={contextValue}>
      {children}
    </CommonContext.Provider>
  );
};

CurrentUserContextProvider.propTypes = {
  children: PropTypes.node,
};

CurrentUserContextProvider.defaultProps = {
  children: null,
};

export default CurrentUserContextProvider;
