import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { Breadcrumb, Empty, Table } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { TeamOutlined, UserOutlined } from '@ant-design/icons';

import { authRequest } from 'auth';
import RelatedDateTimeInfo from 'components/RelatedDateTimeInfo';
import { useHandleTableChange } from 'hooks/useHandleTableChange';
import { useFetchTableData } from 'hooks/useFetchTableData';
import { showWarningToast } from 'utils/makeToast';
import { useCurrentEndUser, useHandleCurrentEndUserAuthObsolete } from 'containers/CurrentUserContextProvider/endUserContextProvider';
import { makeInvokeFuncByErrorCode } from 'auth/utils';

const Accounts = () => {
  const [lastSuccessDateTime, setLastSuccessDateTime] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 8 });
  const [loading, setLoading] = useState(false);
  const currentEndUser = useCurrentEndUser();
  const handleObsolete = useHandleCurrentEndUserAuthObsolete();

  const history = useHistory();

  const columns = useMemo(() => ([{
    title: 'Тип',
    dataIndex: 'type',
    render: (text) => (text === 'agency'
      ? (
        <>
          <TeamOutlined style={{ margin: '0 16px 0 0' }} />
          {' '}
          <span> Агентский</span>
        </>
      )
      : (
        <>
          <UserOutlined style={{ margin: '0 16px 0 0' }} />
          {' '}
          <span> Личный</span>
        </>
      )),
    key: 'type',
  }, {
    title: 'Кабинет',
    dataIndex: 'name',
    key: 'name',
  }]), []);

  const fetchTableData = useCallback((pageSize, currentPage, userId) => {
    authRequest(
      `/api/vk/accounts?vk_user_id=${userId}&limit=${pageSize}&offset=${(currentPage - 1) * pageSize}`,
      null,
      false,
      makeInvokeFuncByErrorCode([403], [handleObsolete]),
    )
      .then(({ data: response = {} }) => {
        setLoading(false);
        const {
          last_success_dt: lastSuccessDT,
          accounts: fetchedAccounts,
          count,
          warning,
        } = response;
        setLastSuccessDateTime(lastSuccessDT);
        setAccounts(fetchedAccounts);
        setPagination({
          pageSize,
          currentPage,
          total: count,
        });
        if (warning) {
          showWarningToast(warning.message, warning.description);
        }
      })
      .catch((error) => {
        // you could just subscribe for error by .catch
        console.log('authRequest', error);
      });
  }, []);

  const handleTableChange = useHandleTableChange({ currentEndUser }, fetchTableData);
  // invokes only if currentEndUser has changed
  useFetchTableData(pagination, currentEndUser, fetchTableData, setLoading);

  if (!currentEndUser) {
    return <Redirect to="" />;
  }

  return (
    <>
      <Header className="site-layout-sub-header-background">
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Рекламные кабинеты</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Content style={{ margin: '0 16px' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <RelatedDateTimeInfo
            message="Список кабинетов выгружен из ВК:"
            dateTime={lastSuccessDateTime}
          />
          <Table
            columns={columns}
            dataSource={accounts}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            locale={{ emptyText: <Empty description={false} /> }}
            rowClassName={(record, index) => (record.is_main ? 'table-row-is-main-account clickable-table-row' : 'clickable-table-row')}
            size="small"
            rowKey="id"
            onRow={(record) => ({
              onClick: () => {
                if (record.virtual_client) {
                  history.push(`/clients/${record.virtual_client.id}/campaigns`);
                } else {
                  history.push(`/accounts/${record.id}/clients`);
                }
              },
            })}
          />
        </div>
      </Content>
    </>
  );
};

export default Accounts;
