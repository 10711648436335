import React from 'react';
import { Badge, Button, Popover } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { bool, func } from 'prop-types';
import { noop } from 'utils';

const DownloadBadgeButton = ({ isAsyncUpdating, onClick }) => {
  if (isAsyncUpdating === undefined) {
    return null;
  }

  if (isAsyncUpdating) {
    return (
      <Badge
        count="Идёт выгрузка"
        style={{ backgroundColor: '#52c41a' }}
      />
    );
  }

  return (
    <Popover content="Выгрузить с VK">
      <Button
        shape="round"
        icon={<SyncOutlined />}
        onClick={onClick}
      />
    </Popover>
  );
};

DownloadBadgeButton.defaultProps = {
  onClick: noop,
  isAsyncUpdating: undefined,
};

DownloadBadgeButton.propTypes = {
  onClick: func,
  isAsyncUpdating: bool,
};

export default DownloadBadgeButton;
