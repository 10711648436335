import React from 'react';
import { Content } from 'antd/es/layout/layout';
import { Routes } from 'routes';
import { Link } from 'react-router-dom';
import { Result } from 'antd';

const PaymentFailPage = () => (
  <Content>
    <Result
      status="error"
      title="Платёж неуспешен"
      extra={<Link to={Routes.balance}> Перейти к балансу </Link>}
    />
  </Content>
);

export default PaymentFailPage;
