import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { request } from 'auth';

const UserConfirm = () => {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    request(`/api/confirm/${token}`, {
      method: 'post',
    })
      .then(({ data }) => {
        setLoading(false);
      });
  }, [token]);

  return loading ? null : <Redirect to="/" />;
};

export default UserConfirm;
