import React from 'react';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';

import { Routes } from 'routes';

import { useAuth } from 'auth';

import UserAccounts from 'pages/UserAccounts';
import Clients from 'pages/Clients';
import Campaigns from 'pages/Campaigns';
import Ads from 'pages/Ads';
import Ad from 'pages/Ad';
import Accounts from 'pages/Accounts';
import VkLogin from 'pages/VKLogin';
import NotFound from 'pages/404NotFound';
import Products from 'pages/Products';
import Login from 'pages/Login';
import PrivateRoute from 'components/PrivateRoute';
import Balance from 'pages/Balance';
import Registration from 'pages/Registration';
import UserConfirm from 'pages/UserConfirm';
import ForgotPassword from 'pages/ForgotPassword';
import ForgotPasswordConfirm from 'pages/ForgotPasswordConfirm';
import PaymentSuccessPage from 'pages/PaymentSuccess';
import PaymentFailPage from 'pages/PaymentFail';
import Contacts from 'pages/Contacts';
import Referrals from 'pages/Referrals';
import Landing from 'pages/Landing';
import AdminPage from 'pages/AdminPage';

const RouterContainer = () => {
  const [isUserLogged] = useAuth();

  return (
    <Switch>
      <Route
        exact
        path={Routes.root}
      >
        {!isUserLogged
          ? <Redirect to={Routes.landing} />
          : <Redirect to={Routes.endUsers} />}
      </Route>
      <PrivateRoute path={Routes.endUsers}>
        <UserAccounts />
      </PrivateRoute>
      <PrivateRoute path={Routes.balance}>
        <Balance />
      </PrivateRoute>
      <PrivateRoute path={Routes.referrals}>
        <Referrals />
      </PrivateRoute>
      <PrivateRoute path={Routes.successPaymentCallback}>
        <PaymentSuccessPage />
      </PrivateRoute>
      <PrivateRoute path={Routes.admin}>
        <AdminPage />
      </PrivateRoute>
      <PrivateRoute path={Routes.contacts}>
        <Contacts />
      </PrivateRoute>
      <PrivateRoute path={Routes.failPaymentCallback}>
        <PaymentFailPage />
      </PrivateRoute>
      <PrivateRoute path={Routes.clientsByAccount}>
        <Clients />
      </PrivateRoute>
      <PrivateRoute path={Routes.campaignsByClient}>
        <Campaigns />
      </PrivateRoute>
      <PrivateRoute path={Routes.adsByCampaign}>
        <Ads />
      </PrivateRoute>
      <PrivateRoute path={Routes.ad}>
        <Ad />
      </PrivateRoute>
      <PrivateRoute path={Routes.accounts}>
        <Accounts />
      </PrivateRoute>
      <PrivateRoute path={Routes.loginCallback}>
        <VkLogin />
      </PrivateRoute>
      <PrivateRoute path={Routes.confirmUser}>
        <UserConfirm />
      </PrivateRoute>
      <Route path={Routes.landing}>
        {isUserLogged
          ? <Redirect to={Routes.root} />
          : <Landing />}
      </Route>
      <Route path={Routes.login}>
        {!isUserLogged
          ? <Login />
          : <Redirect to={Routes.root} />}
      </Route>
      <Route path={Routes.forgotPasswordConfirm}>
        {!isUserLogged
          ? <ForgotPasswordConfirm />
          : <Redirect to={Routes.endUsers} />}
      </Route>
      <Route path={Routes.forgotPassword}>
        {!isUserLogged
          ? <ForgotPassword />
          : <Redirect to={Routes.endUsers} />}
      </Route>
      <Route path={Routes.registration}>
        {!isUserLogged
          ? <Registration />
          : <Redirect to={Routes.endUsers} />}
      </Route>
      <Route path={Routes.products}>
        <Products />
      </Route>
      <Route path={Routes.notFound}>
        <NotFound />
      </Route>
      <Route path="*">
        <Redirect to={Routes.notFound} />
      </Route>
    </Switch>
  );
};

export default RouterContainer;
