import React, {
  useCallback, useState, useMemo,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
  Breadcrumb, Descriptions, Empty, Table,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';

import { authRequest } from 'auth';
import { useHandleTableChange } from 'hooks/useHandleTableChange';

import RelatedDateTimeInfo from 'components/RelatedDateTimeInfo';
import BreadcrumbAccountItem from 'components/BreadcrumbAccountItem';
import DateTime from 'components/DateTime';
import { useFetchTableData } from 'hooks/useFetchTableData';
import { useCurrentEndUser } from 'containers/CurrentUserContextProvider/endUserContextProvider';

const Ads = () => {
  const [lastSuccessDateTime, setLastSuccessDateTime] = useState('');
  const [account, setAccount] = useState();
  const [client, setClient] = useState();
  const [campaign, setCampaign] = useState();
  const [ads, setAds] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isAsyncUpdating, setIsAsyncUpdating] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 8 });
  const [loading, setLoading] = useState(false);
  const currentEndUser = useCurrentEndUser();

  const history = useHistory();

  const { campaign_id: campaignId } = useParams();

  const zeroIfEmpty = (value) => (value || 0);

  const columns = useMemo(() => ([{
    title: 'Кампания',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: (status) => (status === 1
      ? <CaretRightOutlined style={{ margin: '0 16px 0 0', color: 'green' }} />
      : <PauseOutlined style={{ margin: '0 16px 0 0' }} />),
  },
  {
    title: 'Дн. лим.',
    dataIndex: 'day_limit',
    key: 'day_limit',
  },
  {
    title: 'Общ. лим.',
    dataIndex: 'all_limit',
    key: 'all_limit',
  },
  {
    title: 'Ограничение цены',
    dataIndex: 'autobidding_max_cost',
    key: 'autobidding_max_cost',
    render: (costInKopeck) => (costInKopeck
      ? (costInKopeck / 100).toFixed(2)
      : 'Не задано'),
  },
  {
    title: 'Потрачено ₽',
    dataIndex: ['stat', 'spent'],
    key: 'stat_spent',
  },
  {
    title: 'Показы',
    dataIndex: ['stat', 'impressions'],
    key: 'stat_impressions',
  },
  {
    title: 'Переходы',
    dataIndex: ['stat', 'clicks'],
    key: 'stat_clicks',
    render: zeroIfEmpty,
  },
  {
    title: 'Вступления',
    dataIndex: ['stat', 'join_rate'],
    key: 'stat_join_rate',
    render: zeroIfEmpty,
  },
  {
    title: 'CTR %',
    dataIndex: ['stat', 'ctr'],
    key: 'stat_ctr',
  },
  {
    title: 'eCPC ₽',
    dataIndex: ['stat', 'effective_cost_per_click'],
    key: 'stat_effective_cost_per_click',
    render: (value) => (value ? parseFloat(value).toFixed(2) : ''),
  },
  {
    title: 'eCPM ₽',
    dataIndex: ['stat', 'effective_cost_per_mille'],
    key: 'stat_effective_cost_per_mille',
    render: (value) => (value ? parseFloat(value).toFixed(2) : ''),
  }]), []);

  const fetchTableData = useCallback((pageSize, currentPage, userId) => {
    authRequest(`/api/vk/campaigns/${campaignId}/ads?vk_user_id=${userId}&limit=${pageSize}&offset=${(currentPage - 1) * pageSize}`)
      .then(({ data: response = {} }) => {
        setLoading(false);
        const {
          last_success_dt: lastSuccessDT,
          is_async_updating_works: isAsyncUpdatingWorks,
          account: fetchedAccount,
          ads: fetchedAds,
          count,
          client: fetchedClient,
          campaign: fetchedCampaign,
        } = response;
        setLastSuccessDateTime(lastSuccessDT);
        setCampaign(fetchedCampaign);
        setAccount(fetchedAccount);
        setClient(fetchedClient);
        setAds(fetchedAds);
        setIsAsyncUpdating(isAsyncUpdatingWorks);
        setPagination({
          pageSize,
          current: currentPage,
          total: count,
        });
      });
  }, [campaignId]);

  const handleTableChange = useHandleTableChange({ currentEndUser }, fetchTableData);
  useFetchTableData(pagination, currentEndUser, fetchTableData, setLoading);

  if (!currentEndUser) {
    return null;
  }

  return (
    <>
      <Header className="site-layout-sub-header-background">
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>
            {' '}
            <Link to="/accounts">Рекламные кабинеты</Link>
          </Breadcrumb.Item>
          <BreadcrumbAccountItem account={account} />
          <Breadcrumb.Item>
            {client ? (
              <Link to={`/clients/${client.id}/campaigns`}>
                {' '}
                {client.name}
                {' '}
              </Link>
            ) : '...'}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{campaign ? campaign.name : '...'}</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Content style={{ margin: '0 16px' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >

          <Descriptions
            title="Общая информация"
            column={1}
            size="small"
          >
            <Descriptions.Item label="Дневной лимит">
              {campaign && campaign.day_limit ? `${campaign.day_limit} ₽` : 'Не задан'}
            </Descriptions.Item>
            <Descriptions.Item label="Общий лимит">
              {campaign && campaign.all_limit ? `${campaign.all_limit} ₽` : 'Не задан'}
            </Descriptions.Item>
            <Descriptions.Item label="Дата запуска">
              <DateTime dateTime={campaign?.start_time} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата остановки">
              <DateTime dateTime={campaign?.stop_time} />
            </Descriptions.Item>
            <Descriptions.Item label="Дата создания">
              <DateTime dateTime={campaign?.create_time} />
            </Descriptions.Item>
          </Descriptions>

          <RelatedDateTimeInfo
            message="Кампания и её объявления выгружены из ВК:"
            dateTime={lastSuccessDateTime}
          />
          <Table
            columns={columns}
            dataSource={ads}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            locale={{ emptyText: <Empty description={false} /> }}
            size="small"
          />
        </div>
      </Content>
    </>
  );
};

export default Ads;
