import React from 'react';
import Text from 'antd/es/typography/Text';
import { Link } from 'react-router-dom';
import { Routes } from 'routes';
import * as propTypes from 'prop-types';
import { Space } from 'antd';

const AddFromBalanceExtra = ({ balance, minPaymentAmountTopUp }) => (
  <Space direction="vertical">
    <Text>{`Доступно ${balance} руб`}</Text>
    <Text>{`Минимальная сумма ${minPaymentAmountTopUp}`}</Text>
    <Link to={Routes.balance}> Пополнить баланс профиля </Link>
  </Space>
);

AddFromBalanceExtra.propTypes = {
  balance: propTypes.number.isRequired,
  minPaymentAmountTopUp: propTypes.number.isRequired,
};

export default AddFromBalanceExtra;
