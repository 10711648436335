import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Space } from 'antd';

import { Routes } from 'routes';
import logo from 'assets/logo.png';
import offerAgreement from 'assets/files/offer_agreement_draft.pdf';
import styles from './styles.module.scss';

const { Footer } = Layout;

const CommonFooter = () => {
  const location = useLocation();
  if (Routes.landing === location.pathname) {
    return null;
  }

  return (

    <Footer className={styles.wrapper}>
      <Space>
        <Link
          to={Routes.root}
          title="Перейти на главную страницу"
        >
          <img
            src={logo}
            alt="Target Bonus"
          />
        </Link>
        <Link
          to={Routes.contacts}
          title="Контакты"
        >
          Контакты
        </Link>
        <a
          href={offerAgreement}
          target="_blank"
          rel="noreferrer"
        >
          Договор оферты
        </a>
        © 2021–2024
      </Space>
    </Footer>
  );
};

export default CommonFooter;
