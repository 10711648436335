import React from 'react';
import Moment from 'react-moment';
import { string } from 'prop-types';
import 'moment/locale/ru';

const RelatedDateTimeInfo = ({ dateTime, message }) => {
  if (!dateTime) {
    return null;
  }
  return (
    <span style={{ float: 'right' }}>
      {' '}
      {message}
      <Moment
        fromNow
        style={{ backgroundColor: '#b9d4af', marginLeft: '1em' }}
      >
        {dateTime}
      </Moment>
    </span>
  );
};

RelatedDateTimeInfo.propTypes = {
  dateTime: string,
  message: string.isRequired,
};

RelatedDateTimeInfo.defaultProps = {
  dateTime: undefined,
};

export default RelatedDateTimeInfo;
