import React, { useEffect, useState } from 'react';
import { Content } from 'antd/es/layout/layout';
import { Routes } from 'routes';
import { Link } from 'react-router-dom';
import { Button, Result, Spin } from 'antd';
import { authRequest } from 'auth';
import AddPaymentDrawer from 'components/Admin/AddPaymentDrawer';
import { DollarCircleOutlined } from '@ant-design/icons';

const AdminPage = () => {
  const [currentUser, setCurrentUser] = useState();
  const [addPaymentDrawerVisible, setAddPaymentDrawerVisible] = useState(false);

  const showAddPaymentDrawer = () => {
    setAddPaymentDrawerVisible(true);
  };
  const onCloseAddPaymentDrawer = () => {
    setAddPaymentDrawerVisible(false);
  };

  useEffect(() => {
    authRequest('/api/user/profile')
      .then(({ data: response = {} }) => {
        setCurrentUser(response);
      });
  }, []);

  if (!currentUser) {
    return <Spin />;
  }
  if (currentUser && !currentUser.is_admin) {
    return (
      <Content>
        <Result
          status="error"
          title="Страница закрыта"
          extra={<Link to={Routes.root}> На главную </Link>}
        />
      </Content>
    );
  }
  return (
    <Content style={{ margin: '0 16px' }}>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Button
          icon={<DollarCircleOutlined />}
          onClick={showAddPaymentDrawer}
        >
          Пополнить баланс пользователя
        </Button>
        <AddPaymentDrawer
          addPaymentDrawerVisible={addPaymentDrawerVisible}
          onCloseAddPaymentDrawer={onCloseAddPaymentDrawer}
        />
      </div>
    </Content>
  );
};

export default AdminPage;
