import React, { useCallback, useEffect, useState } from 'react';

import {
  Button,
  Drawer, Form, InputNumber, Select,
} from 'antd';

import { bool, func } from 'prop-types';
import { authRequest } from 'auth';
import { showErrorToast, showInfoToast } from 'utils/makeToast';
import { PlusOutlined } from '@ant-design/icons';

const AddPaymentDrawer = ({
  addPaymentDrawerVisible,
  onCloseAddPaymentDrawer,
}) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    authRequest('/api/admin/users')
      .then(({ data: response = {} }) => {
        setUsers(response);
      });
  }, []);

  const onSubmitAddPaymentForm = useCallback((form) => {
    setLoading(true);
    authRequest(
      '/api/admin/payment',
      {
        method: 'post',
        body: JSON.stringify(form),
      },
      false,
      null,
    )
      .then(({ data: response = {} }) => {
        showInfoToast('Платёж создан', `Баланс до: ${response.balance_before} руб. Баланс после: ${response.balance_after} руб.`);
        setLoading(false);
        onCloseAddPaymentDrawer();
      })
      .catch((error) => {
        showErrorToast('Платёж не создан. Ошибка.');
        setLoading(false);
        console.error(error);
      });
  }, []);

  return (
    <Drawer
      title="Пополнение баланса пользователя"
      placement="right"
      width={700}
      visible={addPaymentDrawerVisible}
      onClose={onCloseAddPaymentDrawer}
    >
      <Form
        // layout="inline"
        onFinish={onSubmitAddPaymentForm}
        hideRequiredMark
        initialValues={{
          percentage: 10,
        }}
      >
        <Form.Item name="user_id" label="Выбор пользователя">
          <Select>
            {users
              .map((user) => (
                <Select.Option
                  key={user.id}
                  value={user.id}
                >
                  {`${user.id} ${user.email} ${user.first_name} ${user.last_name}`}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Сумма, руб"
          name="payment_amount"
          rules={[{ required: true, message: 'Введите сумму' }]}
        >
          <InputNumber
            min={1}
          />
        </Form.Item>
        <Form.Item
          label="Процент"
          name="percentage"
          rules={[{ required: true, message: 'Введите процент' }]}
        >
          <InputNumber
            min={0}
            max={90}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            icon={<PlusOutlined />}
          >
            Пополнить
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

AddPaymentDrawer.propTypes = {
  addPaymentDrawerVisible: bool.isRequired,
  onCloseAddPaymentDrawer: func.isRequired,
};

export default AddPaymentDrawer;
