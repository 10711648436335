import React, { useCallback, useMemo, useState } from 'react';

import {
  Alert, Divider,
  Table,
} from 'antd';

import { useCurrentEndUser, useHandleCurrentEndUserAuthObsolete } from 'containers/CurrentUserContextProvider/endUserContextProvider';
import { authRequest } from 'auth';
import { makeInvokeFuncByErrorCode } from 'auth/utils';
import { showWarningToast } from 'utils/makeToast';
import { useFetchTableData } from 'hooks/useFetchTableData';
import * as propTypes from 'prop-types';

const ClientJobsTable = ({
  account,
}) => {
  const [jobsLoading, setJobsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const currentEndUser = useCurrentEndUser();
  const handleObsolete = useHandleCurrentEndUserAuthObsolete();

  const jobsColumns = useMemo(() => ([{
    title: 'Клиент',
    dataIndex: 'client_name',
    key: 'client_name',
  }, {
    title: 'Тип',
    dataIndex: ['meta', 'type'],
    key: 'meta_type',
    render: (value) => (value === 'client_top_up' ? 'Пополнение клиента' : 'Создание клиента'),
  }, {
    title: 'Статус',
    render: (_) => 'в обработке',
  }]), []);

  const fetchJobTableData = useCallback((pageSize, currentPage, userId) => {
    if (account && account.is_main) {
      authRequest(
        `/api/vk/accounts/${account ? account.id : 1900013439}/clients/jobs?vk_user_id=${userId}`,
        null,
        false,
        makeInvokeFuncByErrorCode([403], [handleObsolete]),
      )
        .then(({ data: response = {} }) => {
          setJobsLoading(false);
          const {
            jobs: fetchedJobs,
            warning,
          } = response;
          setJobs(fetchedJobs);
          if (warning) {
            showWarningToast(warning.message, warning.description);
          }
        })
        .catch((error) => {
          console.log('authRequest', error);
        });
    }
  }, [account]);

  // invokes only if currentEndUser has changed
  useFetchTableData(false, currentEndUser, fetchJobTableData, setJobsLoading);

  return (jobs.length
    ? (
      <>
        <Alert
          description="Процесс создания и поплнения клиентов требует времени. Список кабинетов, которые находятся в обработке."
          type="warning"
        />
        <Table
          className="jobs-table"
          columns={jobsColumns}
          dataSource={jobs}
          loading={jobsLoading}
          pagination={false}
          size="small"
        />
        <Divider />
      </>
    )
    : (
      <>
      </>
    ));
};

ClientJobsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  account: propTypes.any.isRequired,
};

export default ClientJobsTable;
