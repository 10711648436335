import { createAuthProvider } from 'react-token-auth';
import { generateCommonRequestError, getResponseHeaders, prepareRequestOptions } from 'auth/utils';

export const [useAuth, authFetch, login, logout] = createAuthProvider(
  {
    accessTokenKey: 'access_token',
    onUpdateToken: (token) => fetch('/api/refresh', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token.refresh_token}`,
      },
    })
      .then((r) => r.json()),
  },
);

export async function commonRequest(operandFunc, url, options, withHeaders, customErrorHandlers) {
  if (!operandFunc && typeof operandFunc !== 'function') {
    throw new Error('Error invocation of request function');
  }

  const responseRaw = await operandFunc(url, options);

  if (responseRaw.status >= 200 && responseRaw.status < 300) {
    const parsedResponse = await responseRaw.json();
    return {
      ...(withHeaders ? { headers: getResponseHeaders(responseRaw.headers) } : {}),
      data: parsedResponse,
    };
  }
  if (customErrorHandlers && typeof customErrorHandlers[responseRaw.status] === 'function') {
    customErrorHandlers[responseRaw.status]();
  }

  if (responseRaw.status === 401) {
    logout();
    return generateCommonRequestError(responseRaw, '401 Unauthorized response', withHeaders);
  }
  if (responseRaw.status === 403) {
    return generateCommonRequestError(responseRaw, '403 Forbidden', withHeaders);
  }
  if (responseRaw.status === 404) {
    return generateCommonRequestError(responseRaw, '404 Requested resource is Not Found', withHeaders);
  }
  if (responseRaw.status === 422) {
    logout();
    return generateCommonRequestError(responseRaw, '422 Unprocessable Entity', withHeaders);
  }
  if (responseRaw.status === 500) {
    return generateCommonRequestError(responseRaw, '500 Internal Server Error', withHeaders);
  }
  if (responseRaw.status >= 400 && responseRaw.status < 800) {
    return generateCommonRequestError(responseRaw, 'An error occurred on server, please try later', withHeaders);
  }
  return generateCommonRequestError(responseRaw, undefined, withHeaders);
}

export const authRequest = async (url, options, withHeaders, customErrorHandlers) => {
  const preparedOptions = prepareRequestOptions(options);
  return commonRequest(authFetch, url, preparedOptions, withHeaders, customErrorHandlers);
};

export const request = async (url, options, withHeaders, customErrorHandlers) => {
  const preparedOptions = prepareRequestOptions(options);

  return commonRequest(fetch, url, preparedOptions, withHeaders, customErrorHandlers);
};
