import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useSetCurrentEndUser } from 'containers/CurrentUserContextProvider/endUserContextProvider';
import { makeVKAuth } from 'pages/VKLogin/asyncOperations';

const VkLogin = () => {
  const { search } = useLocation();
  const setCurrentEndUser = useSetCurrentEndUser();

  useEffect(() => {
    const code = new URLSearchParams(search).get('code');
    makeVKAuth(setCurrentEndUser, code);
  }, [search, setCurrentEndUser]);

  return <Redirect to="/" />;
};

export default VkLogin;
