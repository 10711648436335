import React from 'react';

import { Button } from 'antd';
import {
  PlusSquareOutlined,
} from '@ant-design/icons';

import { bool, func } from 'prop-types';
import { noop } from 'utils';

const CreateClientButton = ({
  isMainAccount,
  showCreateClientDrawer,
}) => {
  if (!isMainAccount) {
    return null;
  }

  return (
    <Button
      icon={<PlusSquareOutlined />}
      onClick={showCreateClientDrawer}
    >
      Новый клиент
    </Button>
  );
};

CreateClientButton.propTypes = {
  isMainAccount: bool,
  showCreateClientDrawer: func,
};

CreateClientButton.defaultProps = {
  isMainAccount: false,
  showCreateClientDrawer: noop,
};

export default CreateClientButton;
