import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Button, Form, Input } from 'antd';
import Layout, { Content } from 'antd/es/layout/layout';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { login, request } from 'auth';
import { Routes } from 'routes';
import FormWrapper from 'components/FormWrapper';

import styles from './styles.module.scss';

const Login = () => {
  const onFinish = useCallback((form) => {
    request('/api/login', {
      method: 'post',
      body: JSON.stringify(form),
    })
      .then(({ data }) => {
        if (data.access_token) {
          login(data);
        } else {
          console.error('login error');
        }
      });
  }, []);

  return (
    <Layout>
      <Content
        className="site-layout-background"
      >
        <FormWrapper
          title="Вход в приложение"
          customClassName={styles.wrapper}
        >
          <Form
            name="normal_login"
            layout="vertical"
            size="large"
            className="login-form"
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[{
                required: true,
                message: 'Пожалуйста, ведите Ваш email адрес!',
              }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{
                required: true,
                message: 'Пожалуйста, введите Ваш пароль!',
              }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Пароль"
              />
            </Form.Item>
            <div className={styles.additionalNotes}>
              <Link
                to={Routes.forgotPassword}
                className="login-form-forgot"
              >
                Восстановить пароль
              </Link>
              <Link to={Routes.registration}>
                Зарегистрироваться
              </Link>
            </div>

            <Button
              type="primary"
              htmlType="submit"
              className={styles.loginButton}
            >
              Войти
            </Button>
          </Form>
        </FormWrapper>
      </Content>
    </Layout>
  );
};

export default Login;
