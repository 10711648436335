import React from 'react';

import {
  Button, Drawer, Form, InputNumber, Select,
} from 'antd';

import { bool, func } from 'prop-types';
import { authRequest } from 'auth';
import { useCurrentEndUser } from 'containers/CurrentUserContextProvider/endUserContextProvider';
import * as propTypes from 'prop-types';
import AddFromReferralBalanceExtra from 'components/EditClientDrawers/AddFromReferralBalanceExtra';
import AddFromBalanceExtra from 'components/EditClientDrawers/AddFromBalanceExtra';

const AddBalanceClientDrawer = ({
  clientId,
  addBalanceDrawerVisible,
  onCloseAddBalanceDrawer,
  balance,
  referralBalance,
  minPaymentAmountTopUp,
  minPaymentReferralAmountTopUp,
}) => {
  const currentEndUser = useCurrentEndUser();
  const addBalanceFormSubmit = (values) => {
    console.log(values);
    authRequest(
      `/api/vk/clients/${clientId}/top_up?vk_user_id=${currentEndUser.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          payment: values.payment,
          referral_payment: values.referral_payment,
          balance_type: values.balance_type,
        }),
      },
      false,
      null,
    )
      .then(({ data: response = {} }) => {
        if (response) {
          console.log(response);
        }
      });
    onCloseAddBalanceDrawer();
  };

  const checkPaymentReferral = (_, value) => {
    if (referralBalance < value) {
      return Promise.reject(new Error('Введено больше доступной суммы'));
    }

    if (value < minPaymentReferralAmountTopUp) {
      return Promise.reject(new Error('Введено меньше минимальной суммы'));
    }

    return Promise.resolve();
  };

  const checkPayment = (_, value) => {
    if (balance < value) {
      return Promise.reject(new Error('Введено больше доступной суммы'));
    }

    if (value < minPaymentAmountTopUp) {
      return Promise.reject(new Error('Введено меньше минимальной суммы'));
    }

    return Promise.resolve();
  };

  return (
    <Drawer
      title="Пополнить баланс кабинета"
      placement="right"
      width={500}
      visible={addBalanceDrawerVisible}
      onClose={onCloseAddBalanceDrawer}
    >
      <Form
        layout="vertical"
        onFinish={(values) => addBalanceFormSubmit(values)}
        hideRequiredMark
        initialValues={{
          payment: minPaymentAmountTopUp,
          referral_payment: minPaymentReferralAmountTopUp,
          balance_type: balance > minPaymentAmountTopUp ? 'balance' : 'referral_balance',
        }}
      >
        <Form.Item
          name="balance_type"
          label="Списать с"
          rules={[{ required: true }]}
        >
          <Select>
            <Select.Option value="balance">Баланс профиля</Select.Option>
            <Select.Option value="referral_balance">Реферальный баланс</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.balance_type !== currentValues.balance_type}
        >
          {({ getFieldValue }) => (getFieldValue('balance_type') === 'balance' ? (
            <Form.Item
              label="Сумма, руб"
              name="payment"
              rules={[{ validator: checkPayment }]}
              extra={(
                <AddFromBalanceExtra
                  balance={balance}
                  minPaymentAmountTopUp={minPaymentAmountTopUp}
                />
              )}
            >
              <InputNumber
                min={minPaymentAmountTopUp}
                max={balance}
              />
            </Form.Item>
          )
            : (
              <Form.Item
                label="Сумма, руб"
                name="referral_payment"
                rules={[{ validator: checkPaymentReferral }]}
                extra={(
                  <AddFromReferralBalanceExtra
                    referralBalance={referralBalance}
                    minPaymentReferralAmountTopUp={minPaymentReferralAmountTopUp}
                  />
                )}
              >
                <InputNumber
                  min={minPaymentReferralAmountTopUp}
                  max={referralBalance}
                />
              </Form.Item>
            )
          )}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            Пополнить
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

AddBalanceClientDrawer.propTypes = {
  clientId: propTypes.string.isRequired,
  addBalanceDrawerVisible: bool.isRequired,
  onCloseAddBalanceDrawer: func.isRequired,
  balance: propTypes.number.isRequired,
  referralBalance: propTypes.number.isRequired,
  minPaymentAmountTopUp: propTypes.number.isRequired,
  minPaymentReferralAmountTopUp: propTypes.number.isRequired,
};

export default AddBalanceClientDrawer;
