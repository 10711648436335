import React from 'react';
import { bool, string, func } from 'prop-types';

import { CheckOutlined } from '@ant-design/icons';
import { Avatar, Button, Card } from 'antd';
import { noop } from 'utils';

const { Meta } = Card;

const UserAccountCard = ({
  avatarSrc,
  isActive,
  isExpired,
  description,
  title,
  handleClick,
}) => (
  <Card
    style={
      isActive
        ? { width: 300, backgroundColor: 'rgb(201,231,199)', marginBottom: 20 }
        : { width: 300, marginBottom: 20 }
    }
    actions={[
      <Button
        disabled={isActive || isExpired}
        icon={<CheckOutlined />}
        onClick={handleClick}
      />,
    ]}
  >
    <Meta
      avatar={<Avatar src={avatarSrc} />}
      title={isExpired ? `${title} (Аккаунт просрочен)` : title}
      description={description}
    />
  </Card>
);

UserAccountCard.propTypes = {
  isActive: bool,
  handleClick: func,
  isExpired: bool.isRequired,
  avatarSrc: string,
  title: string.isRequired,
  description: string.isRequired,
};

UserAccountCard.defaultProps = {
  isActive: false,
  handleClick: noop,
  avatarSrc: null,
};

export default UserAccountCard;
