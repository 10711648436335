import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Link, Redirect, useHistory, useParams,
} from 'react-router-dom';

import {
  Breadcrumb, Descriptions, Empty, Table,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import {
  CaretRightOutlined,
  PauseOutlined,
} from '@ant-design/icons';

import { authRequest } from 'auth';
import { useFetchTableData } from 'hooks/useFetchTableData';

import DownloadBadgeButton from 'components/DownloadBadgeButton';
import RelatedDateTimeInfo from 'components/RelatedDateTimeInfo';
import BreadcrumbAccountItem from 'components/BreadcrumbAccountItem';
import { useHandleTableChange } from 'hooks/useHandleTableChange';
import { useCurrentEndUser } from 'containers/CurrentUserContextProvider/endUserContextProvider';
import EditCampaignButtons from 'containers/EditCampaignButtons';
import EditCampaignDrawers from 'containers/EditCampaignDrawers';
import { showWarningToast } from 'utils/makeToast';

const Campaigns = () => {
  const [lastSuccessDateTime, setLastSuccessDateTime] = useState('');
  const [account, setAccount] = useState();
  const [client, setClient] = useState(undefined);
  const [isAsyncUpdating, setIsAsyncUpdating] = useState();
  const [campaigns, setCampaigns] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 8 });
  const [loading, setLoading] = useState(false);
  const currentEndUser = useCurrentEndUser();

  const history = useHistory();

  const { client_id: clientId } = useParams();

  const [balance, setBalance] = useState(0);
  const [referralBalance, setReferralBalance] = useState(0);
  const [addBalanceDrawerVisible, setAddBalanceDrawerVisible] = useState(false);
  const [assignDrawerVisible, setAssignDrawerVisible] = useState(false);
  const [renameDrawerVisible, setRenameDrawerVisible] = useState(false);

  const minPaymentAmountTopUp = 2000;
  const minPaymentReferralAmountTopUp = 1000;

  const columns = useMemo(() => ([{
    title: 'Кампания',
    dataIndex: 'name',
    key: 'name',

  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: (status) => (status === 1
      ? <CaretRightOutlined style={{ margin: '0 16px 0 0', color: 'green' }} />
      : <PauseOutlined style={{ margin: '0 16px 0 0' }} />),
  },
  {
    title: 'Дн. лим.',
    dataIndex: 'day_limit',
    key: 'day_limit',
  },
  {
    title: 'Общ. лим.',
    dataIndex: 'all_limit',
    key: 'all_limit',
  },
  {
    title: 'Показы',
    dataIndex: ['stat', 'impressions'],
    key: 'stat_impressions',
  },
  {
    title: 'Переходы',
    dataIndex: ['stat', 'clicks'],
    key: 'stat_clicks',
  },
  {
    title: 'CTR %',
    dataIndex: ['stat', 'ctr'],
    key: 'stat_ctr',
  },
  {
    title: 'eCPC ₽',
    dataIndex: ['stat', 'effective_cost_per_click'],
    key: 'stat_effective_cost_per_click',
    render: (value) => (value ? parseFloat(value).toFixed(2) : ''),
  },
  {
    title: 'eCPM ₽',
    dataIndex: ['stat', 'effective_cost_per_mille'],
    key: 'stat_effective_cost_per_mille',
    render: (value) => (value ? parseFloat(value).toFixed(2) : ''),
  },
  ]), []);

  const showAddBalanceDrawer = () => {
    // TODO: fix copy past with similar logic on pages/Clients.jsx
    authRequest(
      '/api/user/balance',
      {
        method: 'GET',
      },
      false,
      null,
    )
      .then(({ data: response = {} }) => {
        const fetchedBalance = parseInt(response.balance, 10);
        const fetchedReferralBalance = parseInt(response.referral_balance, 10);
        setBalance(fetchedBalance);
        setReferralBalance(fetchedReferralBalance);
        if (fetchedBalance < minPaymentAmountTopUp && fetchedReferralBalance < minPaymentReferralAmountTopUp) {
          showWarningToast('Пополните баланс', `Минимальная сумма при пополнении кабинета ${minPaymentAmountTopUp} руб.
           Текущий баланс ${fetchedBalance} руб.
           Минимальная сумма при пополнении по реферальной программе ${minPaymentReferralAmountTopUp} руб.
           Текущий реферальный баланс ${fetchedReferralBalance} руб.`);
        } else {
          setAddBalanceDrawerVisible(true);
        }
      });
  };
  const onCloseAddBalanceDrawer = () => {
    setAddBalanceDrawerVisible(false);
  };

  const showAssignmentDrawer = () => {
    setAssignDrawerVisible(true);
  };
  const onCloseAssignDrawer = () => {
    setAssignDrawerVisible(false);
  };

  const showRenameDrawer = () => {
    setRenameDrawerVisible(true);
  };
  const onCloseRenameDrawer = () => {
    setRenameDrawerVisible(false);
  };

  const updateCampaigns = (userId, pageSize, current, isForce) => {
    authRequest(`/api/vk/clients/${clientId}/campaigns?vk_user_id=${userId}&limit=${pageSize}&offset=${(current - 1) * pageSize}&is_force=${isForce}`)
      .then(({ data: response = {} }) => {
        setLoading(false);
        const {
          last_success_dt: lastSuccessDT,
          is_async_updating_works: isAsyncUpdatingWorks,
          account: fetchedAccount,
          count,
          client: fetchedClient,
          campaigns: fetchedCampaigns,
        } = response;
        setLastSuccessDateTime(lastSuccessDT);
        setCampaigns(fetchedCampaigns);
        setAccount(fetchedAccount);
        setClient(fetchedClient);
        setIsAsyncUpdating(isAsyncUpdatingWorks);
        setPagination({
          pageSize,
          current,
          total: count,
        });
      });
  };

  const updateClientName = useCallback((clientName) => {
    setClient((prevClient) => ({
      ...prevClient,
      name: clientName,
    }));
  }, [client]);

  const fetchTableData = useCallback((pageSize, current, userId) => {
    updateCampaigns(userId, pageSize, current, 0);
  }, [clientId]);

  const handleTableChange = useHandleTableChange({ currentEndUser }, fetchTableData);
  useFetchTableData(pagination, currentEndUser, fetchTableData, setLoading);

  const downloadBtnOnClick = useCallback((event) => {
    event.preventDefault();
    setPagination({ current: 1, pageSize: 8 });
    setLoading(true);
    updateCampaigns(currentEndUser.id, pagination.pageSize, pagination.current, 1);
  }, [client]);

  if (!currentEndUser) {
    return <Redirect to="" />;
  }

  return (
    <>
      <Header className="site-layout-sub-header-background">
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>
            {' '}
            <Link to="/accounts">Рекламные кабинеты</Link>
          </Breadcrumb.Item>
          <BreadcrumbAccountItem account={account} />
          <Breadcrumb.Item>{client ? client.name : '...'}</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Content style={{ margin: '0 16px' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <EditCampaignButtons
            isMainAccount={account ? account.is_main : false}
            showAddBalanceDrawer={showAddBalanceDrawer}
            showAssignmentDrawer={showAssignmentDrawer}
            showRenameDrawer={showRenameDrawer}
          />

          <Descriptions
            title="Общая информация"
            column={1}
            size="small"
          >
            <Descriptions.Item
              label="Дневной лимит"
            >
              {client && client.day_limit ? `${client.day_limit} ₽` : 'Не задан'}
            </Descriptions.Item>
            <Descriptions.Item
              label="Общий лимит"
            >
              {client && client.all_limit ? `${client.all_limit} ₽` : 'Не задан'}
            </Descriptions.Item>
          </Descriptions>

          <DownloadBadgeButton
            isAsyncUpdating={isAsyncUpdating}
            onClick={downloadBtnOnClick}
          />
          <RelatedDateTimeInfo
            message="Кампании и объявления выгружены из ВК:"
            dateTime={lastSuccessDateTime}
          />
          <Table
            columns={columns}
            dataSource={campaigns}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            locale={{ emptyText: <Empty description={false} /> }}
            rowClassName="clickable-table-row"
            size="small"
            rowKey="id"
            onRow={(record) => ({
              onClick: () => {
                history.push(`/campaigns/${record.id}/ads`);
              },
            })}
          />
        </div>
        <EditCampaignDrawers
          isMainAccount={account ? account.is_main : false}
          clientName={client?.name}
          clientId={clientId}
          balance={balance}
          referralBalance={referralBalance}
          minPaymentAmountTopUp={minPaymentAmountTopUp}
          minPaymentReferralAmountTopUp={minPaymentReferralAmountTopUp}
          addBalanceDrawerVisible={addBalanceDrawerVisible}
          onCloseAddBalanceDrawer={onCloseAddBalanceDrawer}
          assignDrawerVisible={assignDrawerVisible}
          onCloseAssignDrawer={onCloseAssignDrawer}
          renameDrawerVisible={renameDrawerVisible}
          onCloseRenameDrawer={onCloseRenameDrawer}
          updateClientName={updateClientName}
        />
      </Content>
    </>
  );
};

export default Campaigns;
